<template>
  <div>
    <div v-if="!isLogged">
      <v-app>
        <router-view />
      </v-app>
    </div>

    <template v-else>
      <!-- User logged in -->
      <div v-if="loaded">
        <main-view />
      </div>

      <template v-else>
        <loader-component />
      </template>
    </template>
    <terms-of-use-modal ref="termsOfUseModal" />
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import LoaderComponent from './components/loader/LoaderComponent.vue';
import MainView from './Main.vue';
import TermsOfUseModal from './components/modals/TermsOfUseModal.vue';

export default {
  name: 'BeloyalApplication',
  components: {
    MainView,
    LoaderComponent,
    TermsOfUseModal,
  },
  data() {
    return {
      loaded: false,
      termsAccepted: false,
    };
  },
  computed: {
    ...mapGetters({
      isLogged: 'auth/isLoggedIn',
      isTermsAccepted: 'auth/isTermsAccepted',
      userLocale: 'auth/userLocale',
    }),
  },
  watch: {
    async isLogged(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        this.loaded = false;
        await this.initApp();
        if (this.isTermsAccepted) {
          this.loaded = true;
        }
      }
    },
    async isTermsAccepted(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.$refs.termsOfUseModal.dialog = true;
      } else {
        this.loaded = true;
      }
    },
  },
  async mounted() {
    if (this.isLogged) {
      await this.initApp();
    }
    if (!this.isTermsAccepted) {
      this.$refs.termsOfUseModal.dialog = true;
    } else {
      this.loaded = true;
    }
  },
  methods: {
    async initApp() {
      await this.initUser();
      this.$i18n.locale = this.userLocale;
    },
    ...mapActions({
      initUser: 'auth/initUser',
    }),
  },
};
</script>

<style>
 /* TODO: Move to styles.css */
.toolbar-summary .toolbar-summary-amount {
    font-size: 160% !important;
}

.no-margin {
    margin: 0 !important;
}

.text-right {
    text-align: right;
}

.chart-border-top {
    border-top: 4px dotted #d3d3d3;
}

.footer-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.footer-wrap .footer-left,
.footer-wrap .footer-right {
    display: flex;
    align-items: center;
}

.footer-wrap .footer-link {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
}
</style>
