<template>
  <v-app>
    <div class="col-xs-12 hidden-xs-only pt-4 pl-1">
      <v-btn
        color="primary"
        class="float-right bl-topUpButton"
        @click="$refs.InviteUserModal.dialog = true"
      >
        <v-icon>add</v-icon>
        {{ $t('add_license_plate') }}
      </v-btn>
    </div>
    <div
      grid-list-sm
      class="mt-15"
    >
      <v-row>
        <v-col class="col-xs-12">
          <license-plate-table />
        </v-col>
      </v-row>
      <add-edit-license-plate-modal ref="InviteUserModal" />
      <v-btn
        class="hidden-sm-and-up float-right mb-8"
        color="primary"
        fab
        large
        dark
        fixed
        bottom
        floating
        @click="$refs.InviteUserModal.dialog = true"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import LicensePlateTable from '@components/licensePlates/LicensePlateTable.vue';
import AddEditLicensePlateModal from '@components/licensePlates/AddEditLicensePlateModal.vue';

export default {
  components: {
    LicensePlateTable,
    AddEditLicensePlateModal,
  },
};
</script>
