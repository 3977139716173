<template>
  <div style="padding-left: 20px; padding-right: 20px;">
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :loading="loading"
      :no-data-text="noDataTranslation"
      hide-default-footer
      class="transparent"
    >
      <template #item="{ item }">
        <tr>
          <td class="text-sm-start">
            {{ item.number }}
          </td>
          <td class="text-right">
            {{ item.name }}
          </td>
          <td class="text-sm-end">
            <v-btn
              :key="item.id"
              color="primary"
              fab
              small
              elevation="1"
              @click="openModal(item.id)"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
      />
    </div>
    <add-edit-license-plate-modal
      :id="planId"
      key="sfsdfsdfs"
      ref="editLicensePlate"
    />
  </div>
</template>

<script>
import common from '@components/financial/common.js';
import axios from '@plugins/axios';
import AddEditLicensePlateModal from '@components/licensePlates/AddEditLicensePlateModal.vue';

export default {
  components: {
    AddEditLicensePlateModal,
  },
  data() {
    return {
      loading: true,
      search: '',
      selected: [],
      pagination: {
        sortBy: ['email'],
        sortDesc: [true],
        itemsPerPage: 8,
      },
      planId: 1,
      icons: common.icons,
      headers: [
        {
          text: this.$i18n.t('license_plate_number'),
          align: 'left',
          value: 'number',
        },
        {
          text: this.$i18n.t('license-plate.name'),
          align: 'right',
          value: 'name',
        },
        {
          text: this.$i18n.t('actions.actions'),
          align: 'right',
          value: 'id',
        },
      ],
      noDataTranslation: this.$i18n.t('no_data'),
      items: [],
    };
  },
  computed: {
    pages() {
      return this.pagination && this.pagination.itemsPerPage
        ? Math.ceil(this.items.length / this.pagination.itemsPerPage) : 0;
    },
  },
  mounted() {
    this.getData();
    // create events hooks
    this.$eventHub.$on('user-list-refresh', this.getData);
  },
  beforeDestroy() {
    // destroy events hooks
    this.$eventHub.$off('user-list-refresh');
  },
  methods: {
    openModal(id) {
      this.planId = id;
      this.$refs.editLicensePlate.dialog = true;
    },
    getData() {
      this.loading = true;
      axios.get(
        '/api/users/license_plates/list',
      )
        .then((response) => {
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
          this.loading = false;
        });
    },
  },
};
</script>
