<template><div /></template>

<script>

import { mapActions } from 'vuex';

export default {
  mounted() {
    this.oauthLogin(this.$route.params.login_token);
    this.$router.push('/transactions');
  },
  methods: {
    ...mapActions({
      oauthLogin: 'auth/loginFromOauth',
    }),
  },
};
</script>
