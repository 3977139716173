/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */

const state = () => ({
  appVersion: process.env.BUILD_VERSION,
  appName: process.env.VUE_APP_NAME,
  locale: process.env.VUE_APP_LOCALE,
  url: process.env.VUE_APP_URL,
  api_url: process.env.VUE_APP_API_URL,
  facebook_fanpage: process.env.VUE_APP_FACEBOOK_LINK,
  register_www_lock: process.env.VUE_APP_REGISTER_WWW_LOCK,
  apple_app_url: process.env.VUE_APP_DOWNLOAD_LINK_APPLE,
  android_app_url: process.env.VUE_APP_DOWNLOAD_LINK_ANDROID,
  tax_payer_info: process.env.VUE_APP_TAX_PAYER_INFO,
});

const getters = {
  appVersion: (state) => state.appVersion,
  appName: (state) => state.appName,
  locale: (state) => state.locale,
  url: (state) => state.url,
  apiUrl: (state) => state.api_url,
  terms_link: (state) => `${state.api_url}/terms/of_use`,
  gdpr_link: (state) => `${state.api_url}/terms/rodo`,
  facebook_link: (state) => state.facebook_fanpage,
  register_www_lock: (state) => state.register_www_lock === 'true',
  apple_app_url: (state) => state.apple_app_url,
  android_app_url: (state) => state.android_app_url,
  tax_payer_info: (state) => state.tax_payer_info === 'true',
};

export default {
  namespaced: true,
  state,
  getters,
};
