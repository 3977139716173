<template>
  <v-stepper
    v-model="step"
    class="elevation-0"
  >
    <v-stepper-header class="hidden-sm-and-down">
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        {{ stepTitles[1] }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        :complete="step > 2"
        step="2"
      >
        {{ stepTitles[2] }}
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <div
          class="text-center mb-12"
          color="grey lighten-1"
          height="200px"
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          />
          <h1>{{ $t('top_up.procesing') }}</h1>
        </div>
      </v-stepper-content>
      <v-stepper-content step="2">
        <div
          class="text-center mb-12"
          color="grey lighten-1"
          height="200px"
        >
          <template v-if="topUpData.status=='confirmed'">
            <v-icon
              style="font-size: 76px"
              color="green darken-2"
            >
              check_circle_outline
            </v-icon>
            <h1>{{ $t('account.top_uped') }}</h1>
          </template>
          <template v-else>
            <v-icon
              style="font-size: 76px"
              color="error "
            >
              error_outline
            </v-icon>
            <h1>{{ $t('account.top_up_fail') }}</h1>
          </template>
          <v-data-table
            v-if="topUpData.status === 'confirmed'"
            :headers="[]"
            :items="topUpDataHeadersConfirmed"
            hide-default-footer
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <td>{{ props.item.text }}</td>
              <td>
                <div v-if="props.item.key == 'value'">
                  {{ topUpData.value }}
                </div>
                <div v-else-if="props.item.key == 'confirmed_timestamp'">
                  {{ $d(new Date(topUpData[props.item.key].replace(' ', 'T')), 'long') }}
                </div>
                <div v-else>
                  {{ topUpData[props.item.key] }}
                </div>
              </td>
            </template>
          </v-data-table>
          <v-data-table
            v-else
            :headers="[]"
            :items="topUpDataHeadersNotConfirmed"
            hide-default-footer
          >
            <template
              slot="items"
              slot-scope="props"
            >
              <td>{{ props.item.text }}</td>
              <td>
                <div v-if="props.item.key == 'initiated_timestamp'">
                  {{ $d(new Date(topUpData[props.item.key].replace(' ', 'T')), 'long') }}
                </div>
                <div v-else>
                  {{ topUpData[props.item.key] }}
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
        <div class="text-center">
          <v-btn
            color="primary"
            @click="closePaymentDialog"
          >
            {{ $t('actions.close') }}
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import axios from '@plugins/axios';

export default {
  data() {
    return {
      step: 1,
      topUpDataHeadersConfirmed: [
        {
          text: this.$t('top_up.id'),
          key: 'id',
        },
        {
          text: this.$t('top_up.value'),
          key: 'value',
        },
        {
          text: this.$t('top_up.issuence_date'),
          key: 'confirmed_timestamp',
        },
      ],
      topUpDataHeadersNotConfirmed: [
        {
          text: this.$t('top_up.id'),
          key: 'id',
        },
        {
          text: this.$t('top_up.value'),
          key: 'value',
        },
        {
          text: this.$t('top_up.initiate_date'),
          key: 'initiated_timestamp',
        },
      ],
      stepTitles: {
        1: this.$t('top_up.step.processing'),
        2: this.$t('top_up.step.summary'),
      },
      topUpData: {
        confirmed_timestamp: null,
        initiated_timestamp: null,
      },
    };
  },
  async mounted() {
    this.getLastTopupData();
  },
  methods: {
    closePaymentDialog() {
      this.$emit('close', {});
    },
    getLastTopupData() {
      const id = localStorage.getItem('last_payment_id');
      if (id !== null) {
        axios.get(
          `/api/users/transactions/${id}`,
        )
          .then((response) => {
            if (response.data) {
              this.topUpData = response.data;
            }
            this.step = 2;
          });
      }
    },
  },
};
</script>

<style scoped>
h2 {
  padding-top: 30px;
  padding-bottom: 10px;
}
</style>
