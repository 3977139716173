import Vue from 'vue';
import VueI18n from 'vue-i18n';
import numberFormats from './numberFormats';
import dateTimeFormats from './dateFormats';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('../', true, /[A-Za-z0-9-_,\s]+\.yml$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_LOCALE,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  numberFormats,
  dateTimeFormats,
});
