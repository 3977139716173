<template>
  <v-app>
    <transactions-table />
  </v-app>
</template>

<script>
import TransactionsTable from '@components/transactions/TransactionsTable.vue';

export default {
  components: {
    TransactionsTable,
  },
};
</script>
