var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-3 py-3",attrs:{"outlined":"","color":"blue-grey lighten-5"}},[_c('h3',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.headerText)+" ")]),(_vm.items.length)?_c('v-simple-table',{staticClass:"elevation-3 py-2",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',_vm._l((_vm.headers),function(ref){
var key = ref[0];
var header = ref[1];
return _c('td',{key:("headers-" + key),staticClass:"border-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(("users.stat." + header)))+" ")])}),0),_vm._l((_vm.items),function(ref){
var key = ref[0];
var value = ref[1];
return _c('tr',{key:("item-" + key)},[_vm._l((Object.entries(value)),function(ref){
var key2 = ref[0];
var arrayItemValue = ref[1];
return [_c('td',{key:("item-" + key + "-item-array-value-" + key2),staticClass:"border-right "},[(_vm.isNumberParsedAsString(arrayItemValue) && key2 !== 'count')?[_vm._v(" "+_vm._s(_vm._f("customCurrency")(arrayItemValue,_vm.currencyCode))+" ")]:(key2 === 'issuer' || key2 === 'type')?[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(arrayItemValue),expression:"arrayItemValue"}]},[_vm._v(" "+_vm._s(_vm.icons[arrayItemValue])+" ")]),_vm._v(" "+_vm._s(arrayItemValue ? _vm.texts[arrayItemValue] : '-')+" ")]:[_vm._v(" "+_vm._s(arrayItemValue ? arrayItemValue : '-')+" ")]],2)]})],2)})],2)]},proxy:true}],null,false,398737960)}):_c('v-card-text',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert-circle-outline ")]),_c('i',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('no_data')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }