<template>
  <v-app>
    <v-content
      class="fill-height bg-image"
    >
      <v-container
        fluid
        justify-center
        fill-height
      >
        <v-container>
          <v-row
            class="flex-column"
            align="center"
            justify="center"
          >
            <div class="bl-logo__wrapper">
              <v-img
                class="bl-logo py-4"
                contain
              />
            </div>
            <v-alert
              transition="slide-y-transition"
              :value="!!message.text"
              :type="message.type"
            >
              {{ message.text }}
            </v-alert>
            <v-card
              class="d-block w-100 mb-7"
              width="650"
              elevation="6"
              raised
              outlined
            >
              <v-tabs-items
                key="confirm"
                v-model="tab"
              >
                <!--Reset-->
                <v-tab-item
                  key="new-user-confirmed"
                >
                  <v-card-title>
                    <v-row>
                      <v-col
                        class="justify-center align-center text-center"
                      >
                        <template
                          v-if="!registerWWWLock"
                        >
                          <p class="word-break-normal">
                            {{ $t('email_verification.your-account-is-active') }}
                          </p>
                          <v-btn
                            color="primary"
                            @click="goToLoginPage()"
                          >
                            {{ $t('new_password.go_to_login') }}
                          </v-btn>
                        </template>
                        <template v-else>
                          <div>
                            <h3
                              class="mt-5 mb-5 text-center"
                            >
                              {{ $t('email_verification.back-to-app-and-sign-in') }}
                            </h3>
                          </div>
                          <v-row justify-center>
                            <v-col
                              md="6"
                              sm="6"
                              xs="6"
                              class="text-center"
                            >
                              <a :href="androidAppUrl">
                                <img
                                  justify-center
                                  style="height: 85px"
                                  src="~@assetsCommon/images/stores/google-play-badge.png"
                                >
                              </a>
                            </v-col>
                            <v-col
                              md="6"
                              sm="6"
                              xs="6"
                              class="text-center"
                            >
                              <a :href="appleAppUrl">
                                <img
                                  justify-center
                                  class="mt-3"
                                  style="height: 58px"
                                  src="~@assetsCommon/images/stores/app-store-badge.png"
                                >
                              </a>
                            </v-col>
                          </v-row>
                        </template>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-tab-item>

                <!--Reset-->
                <v-tab-item
                  key="new-user-confirmed-fail"
                >
                  <v-card-title>
                    <v-row>
                      <v-col class="justify-center align-center text-center">
                        <p class="word-break-normal">
                          {{ $t('email_verification.sign-up-inactive-token') }}
                        </p>
                        <v-btn
                          color="primary"
                          @click="goToLoginPage()"
                        >
                          {{ $t('new_password.go_to_login') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-row>
        </v-container>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      success: this.$route.params.success,
      loading: false,
      tab: (this.$route.params.success === 'success') ? 0 : 1,
      message: {
        type: 'error',
        text: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      appName: 'branding/appName',
      appleAppUrl: 'branding/apple_app_url',
      androidAppUrl: 'branding/android_app_url',
      registerWWWLock: 'branding/register_www_lock',
    }),
  },
  methods: {
    goToLoginPage() {
      this.$router.push('/login');
    },
  },
};
</script>
