<template>
  <div class="text-center">
    <template v-if="loading">
      <v-progress-circular
        :width="5"
        :size="70"
        indeterminate
        color="primary"
      />
    </template>
    <template v-else>
      <h2
        v-show="!missingInvoiceDataAlert"
        class="mt-6 mb-2"
      >
        {{ $t('payment_method') }}
      </h2>
    </template>
    <div v-show="missingInvoiceDataAlert">
      <h2 class="mt-4 mb-4">
        {{ $t('top_up.invoice_data_not_set') }}
      </h2>
      <h3 class="mt-4 pb-12">
        {{ $t('top_up.goto_menu_invoice_data') }}
      </h3>
    </div>
    <v-container>
      <v-row
        v-show="!missingInvoiceDataAlert"
        col="12"
        class="justify-center"
      >
        <v-col
          v-for="topUpMethod in activeTopUpMethods"
          :key="topUpMethod.id"
          col="12"
          sm="6"
          class="mb-2 flex-grow-1"
        >
          <v-card
            class="elevation-0 ml-1 mr-1 pt-2 pb-2 pl-2 pr-2"
            style="background: #ada5a529"
          >
            <h2>{{ topUpMethod.title }}</h2>
            <div class="mt-2 mb-2">
              <v-btn
                :key="topUpMethod.id"
                large
                color="primary"
                @click="choseMethod(topUpMethod.token, topUpMethod.pay_system)"
              >
                {{ $t('top_up.top_up') }}
              </v-btn>
            </div>
            <h4>{{ topUpMethod.description }}</h4>
            <img
              v-if="topUpMethod.id == 'p24'"
              class="mt-6"
              style="height: 25px"
              src="~@assetsCommon/images/pay-methods/przelewy24_logo.png"
            >
            <img
              v-if="topUpMethod.id == 'pay_ex'"
              class="mt-6"
              style="height: 25px"
              src="~@assetsCommon/images/pay-methods/pay-ex-logo.png"
            >
            <v-icon
              v-if="topUpMethod.id == 'bankCard'"
              x-large
            >
              mdi-credit-card-outline
            </v-icon>
            <div
              v-if="topUpMethod.id != 'p24' && topUpMethod.id != 'pay_ex'"
              class="mt-6"
              style="height: 25px; display: inline-block;"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import axios from '@plugins/axios';

export default {
  data() {
    return {
      loading: true,
      topUpMethodsResponse: [],
    };
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/getUser',
    }),
    missingInvoiceDataAlert() {
      return !this.getUser.isInvoiceDataSet && this.isFleetManager;
    },
    activeTopUpMethods() {
      return this.topUpMethodsResponse.map((item) => ({
        ...item,
        key: item.pay_system,
        id: item.pay_type,
        title: item.title,
        description: item.description,
      }));
    },
  },
  mounted() {
    this.getPayMethodsList();
  },
  methods: {
    getPayMethodsList() {
      this.loading = true;
      axios.post(
        '/api/users/mobilepayment',
        {
          methodId: 'getTopUpValueMethods',
        },
      )
        .then((response) => {
          if (response.data) {
            this.topUpMethodsResponse = response.data.data;
          }
          this.loading = false;
        });
    },
    choseMethod(token, paySystem) {
      this.$emit(
        'showPaymentSteps',
        {
          token,
          paySystem,
          paymentTypeKey: 'payment',
          step: 1,
        },
      );
    },
  },
};
</script>
