export default {
  pl: {
    currency: {
      style: 'currency',
      currency: 'PLN',
      currencyDisplay: 'symbol',
    },
    PLN: {
      style: 'currency',
      currency: 'PLN',
      currencyDisplay: 'symbol',
    },
    EUR: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
    RUB: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
    },
    USD: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    },
    SEK: {
      style: 'currency',
      currency: 'SEK',
      currencyDisplay: 'symbol',
    },
  },
  sv: {
    currency: {
      style: 'currency',
      currency: 'SEK',
      currencyDisplay: 'symbol',
    },
    PLN: {
      style: 'currency',
      currency: 'PLN',
      currencyDisplay: 'symbol',
    },
    EUR: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
    RUB: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
    },
    USD: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    },
    SEK: {
      style: 'currency',
      currency: 'SEK',
      currencyDisplay: 'symbol',
    },
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
    PLN: {
      style: 'currency',
      currency: 'PLN',
      currencyDisplay: 'symbol',
    },
    EUR: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
    RUB: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
    },
    USD: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    },
    SEK: {
      style: 'currency',
      currency: 'SEK',
      currencyDisplay: 'symbol',
    },
  },
};
