/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import store from '@store';
import router from '@/router';

const config = {
  headers: {},
  baseURL: process.env.VUE_APP_API_URL,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    if (!config.__isRetryRequest) {
      if (localStorage.getItem('access_token') !== null) {
        const auth = localStorage.getItem('access_token');
        const accessToken = auth || null;
        if (accessToken !== null) {
          config.headers.Authorization = `${accessToken}`;
        }
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
  ,
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  async (error) => {
    if (error.response.status === 401 && !error.config.__isRetryRequest) {
      store.dispatch('auth/logout').then(() => {
        if (router.history.current.name !== 'login') {
          router.push({ name: 'login' });
        }
      });
      return Promise.resolve(error);
    }

    return Promise.reject(error);
  },
);

export default _axios;
