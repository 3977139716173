<script>

export default {
  name: 'NumericValueMixin',
  data() {
    return {
      reportName: 'export',
    };
  },
  methods: {
    isNumberParsedAsString(variable) {
      return variable !== null && !Number.isNaN(Number(variable));
    },
  },
};
</script>
