<template>
  <div class="d-flex flex-column">
    <div
      v-if="showPresets"
      class="py-0"
    >
      <v-select
        v-model="currentPreset"
        :color="color"
        :dense="dense"
        class="d-flex"
        :items="availablePresets"
        :hide-details="dense"
        :label="label"
        :prepend-icon="prependIcon"
        :menu-props="{maxHeight: 500}"
        :disabled="disabled"
        @change="onPresetChange"
      />
    </div>
    <v-scroll-y-transition>
      <div
        v-if="showDatePicker"
        class="py-0"
      >
        <v-dialog
          ref="dialog"
          v-model="dateRangeModal"
          width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeFormatted"
              :label="$t('filters.in_period')"
              readonly
              :disabled="disabled"
              :prepend-icon="prependIcon"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dateRange"
            range
            :locale="locale"
            scrollable
            :min="dateMin"
            :max="dateMax"
            class="dateRange"
            :disabled="disabled"
            :selected-items-text="$t('date.range.selected')"
            @change="swapDates"
          >
            <v-spacer />
            <v-btn
              text
              @click="cancel"
            >
              {{ $t('update_cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="confirm"
            >
              {{ $t('select') }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import {
  endOfToday,
  endOfYesterday,
  startOfMonth,
  startOfToday,
  startOfYesterday,
  format,
  startOfYear,
  subDays,
  startOfDay,
  subMonths,
  subYears,
  endOfMonth,
  endOfYear,
} from 'date-fns';

export default {
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default() {
        return this.$t('filters.in_period');
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    presets: {
      type: Array,
      default() {
        return [
          {
            value: 'today',
            text: this.$t('filters.daterange.today'),
            start: startOfToday(),
            end: endOfToday(),
          },
          {
            value: 'yesterday',
            text: this.$t('filters.daterange.yesterday'),
            start: startOfYesterday(),
            end: endOfYesterday(),
          },
          {
            value: 'last7Days',
            text: this.$t('filters.daterange.period.P7D'),
            start: startOfDay(subDays(new Date(), 6)),
            end: endOfToday(),
          },
          {
            value: 'last14Days',
            text: this.$t('filters.daterange.period.P14D'),
            start: startOfDay(subDays(new Date(), 13)),
            end: endOfToday(),
            default: true,
          },
          {
            value: 'currentMonth',
            text: this.$t('filters.daterange.since-month-start'),
            start: startOfMonth(new Date()),
            end: endOfToday(),
          },
          {
            value: 'previousMonth',
            text: this.$t('filters.daterange.previous-month'),
            start: startOfMonth(subMonths(new Date(), 1)),
            end: endOfMonth(subMonths(new Date(), 1)),
          },
          {
            value: 'currentYear',
            text: this.$t('filters.daterange.current-year'),
            start: startOfYear(new Date()),
            end: endOfToday(),
          },
          {
            value: 'previousYear',
            text: this.$t('filters.daterange.previous-year'),
            start: startOfYear(subYears(new Date(), 1)),
            end: endOfYear(subYears(new Date(), 1)),
          },
        ];
      },
    },
    showPresets: {
      type: Boolean,
      default: false,
    },
    showCustom: {
      type: Boolean,
      default: true,
    },
    startDateRange: {
      type: Array,
      default() {
        return [
          format(subDays(new Date(), 7), 'YYYY-MM-DD'),
          format(new Date(), 'YYYY-MM-DD'),
        ];
      },
    },
    startPreset: {
      type: String,
      default: 'last7Days',
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss',
    },
    settingsNamespace: {
      type: String,
      default: null,
    },
    dateMinimum: {
      type: String,
      default: '2006-01-01',
    },
    dateMaximum: {
      type: String,
      default: () => new Date().toISOString().slice(0, 10),
    },
  },
  data() {
    return {
      current: 'last7Days',
      locale: this.$i18n.locale,
      dateRangeModal: false,
      dateRangeOld: [format(subDays(new Date(), 7), 'YYYY-MM-DD'), format(new Date(), 'YYYY-MM-DD')],
      dateRange: this.startDateRange,
      dateMin: this.dateMin,
      dateMax: this.dateMaximum,
    };
  },
  computed: {
    currentPreset: {
      get() {
        if (this.current === 'custom') {
          return this.current;
        }

        if (!this.showPresets || !this.presets.length) {
          return null;
        }

        const defaultPresets = this.presets.filter((preset) => ('value' in preset) && preset.value === this.startPreset);

        if (!defaultPresets.length) {
          return this.presets[0].value;
        }

        return defaultPresets[0].value;
      },
      set(value) {
        this.current = value;
      },
    },
    showDatePicker() {
      return (this.showPresets && this.currentPreset === 'custom') || !this.showPresets;
    },
    availablePresets() {
      const availablePresets = [...this.presets];
      if (this.showCustom) {
        availablePresets.push({
          value: 'custom',
          text: this.$t('filters.daterange.custom'),
        });
      }

      return availablePresets;
    },
    today() {
      return new Date().toISOString()
        .slice(0, 10);
    },
    dateRangeFormatted() {
      let dateRangeSecond;
      if (this.dateRange[1]) {
        if (this.dateRange[1] === this.dateRange[0]) {
          return this.$options.filters.formatDateDay(this.dateRange[0]);
        }
        [, dateRangeSecond] = this.dateRange;
        dateRangeSecond = this.$options.filters.formatDateDay(dateRangeSecond);
      } else {
        dateRangeSecond = '...';
      }

      return `${this.$options.filters.formatDateDay(this.dateRange[0])} – ${dateRangeSecond}`;
    },
  },
  watch: {
    presets(newPresets) {
      const newPresetsValues = newPresets.map((p) => p.value);
      if (newPresetsValues.indexOf(this.currentPreset) > -1) {
        this.onPresetChange(this.currentPreset);
        return;
      }
      this.resetPreset();
    },
  },
  mounted() {
    if (this.showPresets) {
      this.onPresetChange(this.currentPreset);
    } else {
      this.confirm();
    }
  },
  methods: {
    resetPreset() {
      this.onPresetChange(this.getStartingPreset());
    },
    onPresetChange(value) {
      const item = this.getPresetByValue(value);
      this.current = item.value;

      if (!item) {
        return;
      }
      if (item.value === 'all') {
        this.$emit('reload-transaction-list', {
          from: null,
          to: null,
          isLastCollection: false,
        });
        return;
      }
      if (item.value === 'lastCollection') {
        this.$emit('reload-transaction-list', {
          from: null,
          to: null,
          isLastCollection: true,
        });
        return;
      }
      if (!('start' in item)) {
        return;
      }
      this.dateRange.splice(0, 1, format(item.start, this.format));
      this.dateRange.splice(1, 1, format(item.end, this.format));
      this.confirm();
    },
    getPresetByValue(value) {
      const presets = this.availablePresets.filter((preset) => preset.value === value);

      return presets[0] ?? null;
    },
    swapDates() {
      if (this.dateRange[0] > this.dateRange[1]) {
        const temp = this.dateRange[0];
        this.dateRange.splice(0, 1, this.dateRange[1]);
        this.dateRange.splice(1, 1, temp);
      }
    },
    showDateModal() {
      this.dateRangeModal = true;
    },
    confirm() {
      if (!this.dateRange[0] || !this.dateRange[1]) {
        this.cancel();
        return;
      }
      this.dateRangeOld = this.dateRange;
      this.closeDateModal();
      this.$emit('reload-transaction-list', {
        value: this.current ?? undefined,
        from: this.dateRange[0],
        to: this.dateRange[1],
        isLastCollection: false,
      });
    },
    cancel() {
      this.dateRange = this.dateRangeOld;
      this.closeDateModal();
    },
    closeDateModal() {
      this.dateRangeModal = false;
    },
    getStartingPreset() {
      if (!this.showPresets || !this.presets.length) {
        return null;
      }

      const defaultPresets = this.presets.filter((preset) => ('value' in preset) && preset.value === this.startPreset);

      if (!defaultPresets.length) {
        return this.presets[0].value;
      }
      return defaultPresets[0].value;
    },
  },
};
</script>
