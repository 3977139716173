<template>
  <div>
    <h2 class="text-center">
      {{ $t('top_up.unpaid_invoices') }}
    </h2>
    <v-data-table
      :disable-pagination="true"
      :hide-default-footer="true"
      :headers="headers"
      :items="sortedItems"
    >
      <template #item="{ item }">
        <tr>
          <td class="border-right">
            {{ item.number }}
          </td>
          <td
            class="border-right"
            nowrap
          >
            {{ $n(item.price, item.currency.code) }}
          </td>
          <td :class="getPaymentDateClass(item.payment_date)">
            {{ $d(new Date(item.payment_date.replace(' ', 'T'))) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t('invoice.number'),
          value: 'number',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$i18n.t('payment_value'),
          value: 'price',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$i18n.t('invoices.payment-date'),
          align: 'center',
          value: 'payment_date',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    sortedItems() {
      const sorted = this.items.slice().sort((a, b) => new Date(a.payment_date.replace(' ', 'T')) - new Date(b.payment_date.replace(' ', 'T')));

      return sorted;
    },
  },
  methods: {
    getPaymentDateClass(paymentDate) {
      const currentDate = new Date();
      const twoWeeksAgo = new Date(currentDate);
      twoWeeksAgo.setDate(currentDate.getDate() - 14);

      const date = new Date(paymentDate.replace(' ', 'T'));
      if (date < twoWeeksAgo) {
        return 'not-paid';
      }

      return '';
    },
  },
};
</script>

<style scoped>
.not-paid {
  color: red;
  font-weight: bold;
}
</style>
