<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="primary"
        elevation="1"
        :small="small"
        :x-small="xSmall"
        :fab="tile"
        :tile="tile"
        :href="href"
        :loading="loading"
        v-on="on"
        @click="$emit('click')"
      >
        <template
          v-if="xSmall && !tile"
          #loader
        >
          <v-progress-circular
            indeterminate
            size="12"
            width="2"
            color="white"
          />
        </template>
        <v-icon
          :left="!tile"
        >
          mdi-cloud-download
        </v-icon>
        <span v-if="!tile">
          {{ text ? text : $t('download') }}
        </span>
      </v-btn>
    </template>
    <span>
      {{ text ? text : $t('download') }}
    </span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'BtnDownload',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    href: {
      type: [String, Object],
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>
