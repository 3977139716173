var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-3 py-3",attrs:{"outlined":"","color":"blue-grey lighten-5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h3',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.headerText)+" ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right",attrs:{"elevation":"1","tile":"","color":"primary","x-small":"","fab":""},nativeOn:{"click":function($event){return _vm.openEditModal(_vm.userId)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" edit ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('users.edit-limit')))])])],1)],1),_c('edit-user-limits-modal',{key:("EditUserModal" + _vm.userId),ref:("EditUserModal" + _vm.userId),attrs:{"user-id":_vm.userId}}),(_vm.items.length)?_c('v-simple-table',{staticClass:"elevation-3 py-2",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',{staticClass:"border-right"}),_vm._l((_vm.headers),function(ref){
var key = ref[0];
var header = ref[1];
return _c('td',{key:("headers-" + key),staticClass:"border-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(("users.limit." + header)))+" ")])})],2),_vm._l((_vm.items),function(ref){
var key = ref[0];
var value = ref[1];
return _c('tr',{key:("item-" + key)},[_c('td',{key:("itemName-" + key),staticClass:"border-right text-left font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(("users.limit." + key)))+" ")]),_vm._l((Object.entries(value)),function(ref){
var key2 = ref[0];
var arrayItemValue = ref[1];
return [_c('td',{key:("item-" + key + "-item-array-value-" + key2),staticClass:"border-right"},[(_vm.isNumberParsedAsString(arrayItemValue))?[_vm._v(" "+_vm._s(_vm._f("customCurrency")(arrayItemValue,_vm.currencyCode))+" ")]:[_vm._v(" "+_vm._s(arrayItemValue ? arrayItemValue : '-')+" ")]],2)]})],2)})],2)]},proxy:true}],null,false,4264883578)}):_c('v-card-text',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alert-circle-outline ")]),_c('i',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t('no_data')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }