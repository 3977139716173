var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-left":"20px","padding-right":"20px"}},[_c('v-layout',{attrs:{"justify-end":"","align-center":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('text-search',{attrs:{"disabled":_vm.loading},on:{"input":_vm.getDataDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('date-range-picker',{key:"dateRangeUsers",ref:"dateRangeUsers",attrs:{"show-presets":true,"show-custom":false,"start-preset":"currentMonth"},on:{"reload-transaction-list":_vm.onDateRangeChange}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"float-right bl-topUpButton mt-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.InviteUserModal.dialog = true}}},[_c('v-icon',[_vm._v("supervisor_account")]),_vm._v(" "+_vm._s(_vm.$t('users.invite'))+" ")],1)],1)],1)],1),_c('div',{staticClass:"mt-15"},[_c('invite-user-modal',{ref:"InviteUserModal"})],1),_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","options":_vm.pagination,"loading":_vm.loading,"no-data-text":_vm.noDataTranslation,"single-expand":true},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{key:("user-item-row-" + (item.id))},[_c('td',{staticClass:"text-sm-start",on:{"click":function($event){return expand(!isExpanded)}}},[('a' !== item.status)?_c('v-icon',[_vm._v(" lock ")]):_vm._e(),_vm._v(" "+_vm._s(item.email)+" ")],1),_c('td',{staticClass:"text-center",on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(" "+_vm._s(_vm.$d(new Date(item.ctime.replace(' ', 'T')), 'long'))+" ")]),_c('td',{staticClass:"text-center"},[(item.invitation_accepted)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close-circle-outline ")])],1),_c('td',{staticClass:"text-sm-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!item.invitation_accepted),expression:"!item.invitation_accepted"}],staticClass:"mr-2",attrs:{"elevation":"1","fab":"","tile":"","color":"primary","x-small":""},nativeOn:{"click":function($event){return _vm.reinviteUser(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-email-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('users.resend_invitation')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:('a' === item.status),expression:"'a' === item.status"}],attrs:{"elevation":"1","tile":"","color":"primary","x-small":"","fab":""},nativeOn:{"click":function($event){return _vm.lockUser(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" lock ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('users.lock')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:('a' != item.status),expression:"'a' != item.status"}],attrs:{"elevation":"1","fab":"","tile":"","color":"primary","x-small":""},nativeOn:{"click":function($event){return _vm.unlockUser(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" lock_open ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('users.unlock')))])])],1),_c('td',{staticClass:"text-sm-end"},[_c('div',{on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)])])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(!_vm.loading)?_c('td',{attrs:{"colspan":_vm.headers.length}},[(item.stats !== null && item.stats.length > 0)?_c('stats-table',{attrs:{"header-text":_vm.$t('users.stats'),"data":item.stats,"currency-code":item.currency.code ? item.currency.code : undefined}}):_vm._e(),(item.limit !== null && Object.keys(item.limit).length > 0)?_c('limit-table',{attrs:{"header-text":_vm.$t('users.limits'),"currency-code":item.currency.code ? item.currency.code : undefined,"user-id":item.id,"data":item.limit}}):_vm._e()],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }