<template>
  <v-app>
    <v-container
      fill-height
    >
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <div>
          <v-progress-circular
            :width="5"
            :size="70"
            indeterminate
            color="primary"
            class="m-3"
          />
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: 'LoaderComponent',

};
</script>
