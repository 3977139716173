<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    width="500"
  >
    <v-card>
      <v-card-title
        dense
        class="primary white--text pb-4 mb-7"
      >
        <v-icon
          dark
          class="mr-2"
        >
          assignment
        </v-icon>
        {{ $t('menu.terms_of_use') }}
      </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="checkboxTerms"
          required
          hide-details
        >
          <template #label>
            <span class="mr-1">{{ $t('i_accept') }}</span>
            <a
              :href="termsOfUseHref"
              target="_blank"
              @click.stop
            >
              {{ $t('terms_of_use') }}
            </a>
          </template>
        </v-checkbox>
        <v-checkbox
          v-model="checkboxGdpr"
          required
          hide-details
        >
          <template #label>
            <span class="mr-1">{{ $t('terms_of_use.consent') }}</span>
            <a
              :href="gdprHref"
              target="_blank"
              @click.stop
            >
              {{ $t('terms_of_use.gdpr') }}
            </a>
          </template>
        </v-checkbox>
      </v-card-text>
      <v-card-actions class="text-right justify-end">
        <v-btn
          color="primary"
          :disabled="!allCheckboxesChecked"
          :loading="showDialogLoader"
          @click="accept"
        >
          {{ $t('terms_of_use.next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import axios from '@plugins/axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      checkboxTerms: false,
      checkboxGdpr: false,
      dialogLoader: false,
      dialog: false,
      termsOfUseAccepted: this.$store.state.auth.user.terms,
    };
  },
  computed: {
    allCheckboxesChecked() {
      return this.checkboxTerms && this.checkboxGdpr;
    },
    showDialogLoader() {
      return this.dialog && this.dialogLoader;
    },
    ...mapGetters({
      termsOfUseHref: 'branding/terms_link',
      gdprHref: 'branding/gdpr_link',
    }),
  },
  methods: {
    close() {
      this.dialog = false;
    },
    accept() {
      this.dialogLoader = true;
      axios.post('/api/users/accept_terms_of_use')
        .then(() => {
          this.dialogLoader = false;
          this.$router.push('/invoices');
          window.location.reload();
        });
    },
    logoutFromApplication() {
      this.dialogLoader = false;
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style>

</style>
