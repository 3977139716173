const dateShortFormat = {
  pl: {
    year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Europe/Warsaw',
  },
  sv: {
    year: 'numeric', month: 'short', day: 'numeric', timeZone: 'Europe/Warsaw',
  },
};

const dateLongFormat = {
  pl: {
    year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Warsaw',
  },
  sv: {
    year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Warsaw',
  },
};

const dateYearMonth = {
  pl: { year: 'numeric', month: 'numeric' },
  sv: { year: 'numeric', month: 'numeric' },
};

const locale = process.env.VUE_APP_LOCALE;

export default {
  [locale]: {
    short: dateShortFormat[locale],
    long: dateLongFormat[locale],
    yearMonth: dateYearMonth[locale],
  },
};
