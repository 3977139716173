<script>

export default {
  name: 'ExportMixin',
  data() {
    return {
      reportName: 'export',
    };
  },
  methods: {
    onExport({
      url,
      filetype,
      filename,
      params = {},
    }) {
      return this.axios.get(url, {
        responseType: 'blob',
        params,
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: filetype });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
  },
};
</script>
