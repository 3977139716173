<template>
  <v-row justify-center>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="700px"
      height="100vh"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-btn
            icon
            dark
            @click.native="closeDialog()"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-icon>account_balance_wallet</v-icon>
            {{ $t('users.invite') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-progress-linear
          class="no-margin"
          :indeterminate="loading"
        />
        <v-card-text>
          <v-row wrap>
            <v-col
              class="col-12 col-sm-12 col-md-12"
              pt-4
            >
              <v-alert
                :value="true"
                type="info"
                class="alert-notice"
              >
                {{ $t('users.invitation_info') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-container grid-list-md>
            <v-form
              ref="form"
              v-model="form.valid"
              :lazy-validation="true"
            >
              <v-row wrap>
                <v-col class="col-12 col-sm-12 col-md-12">
                  <v-text-field
                    v-model="email"
                    prepend-icon="person"
                    :label="$t('email')"
                    :rules="form.validationRules.email"
                    required
                    :validate-on-blur="form.validateOnBlur"
                  />
                  <small>*{{ $t('field_is_required') }}</small>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            :disabled="loaders.submit"
            text
            @click.native="closeDialog"
          >
            {{ $t('actions.return_to_list') }}
          </v-btn>
          <v-btn
            color="primary"
            :loading="loaders.submit"
            @click.native="submit"
          >
            {{ $t('actions.send_invitation') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from '@plugins/axios';

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      loaders: {
        submit: false,
      },
      email: '',
      form: {
        validateOnBlur: true,
        valid: true,
        validationRules: {
          email: [
            (v) => /^([a-zA-Z0-9_\-.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
              v,
            )
              || this.$t('form.validation.invalid_value'),
          ],
        },
      },
    };
  },
  methods: {
    submit() {
      this.form.validateOnBlur = false;
      if (this.$refs.form.validate()) {
        this.loaders.submit = true;
        axios
          .post('/api/users/invite_user', {
            email: this.email,
          })
          .then(
            (response) => {
              if (response.status === 200) {
                this.closeDialog();
                this.$eventHub.$emit(
                  'user-list-refresh',
                  response.data,
                );
              }
              this.$eventHub.$emit(
                'show_application_snackbar',
                'success',
                this.$t('payment_success'),
              );
              this.closeDialog();
            },
            (error) => {
              if (error.request && error.request.status === 409) {
                this.$eventHub.$emit(
                  'show_application_snackbar',
                  'warning',
                  this.$t('errors.user-already-exists'),
                );
              } else if (error.request && error.request.status === 400) {
                this.$eventHub.$emit(
                  'show_application_snackbar',
                  'warning',
                  this.$t('no_permissions'),
                );
              }
              // on error
              this.closeDialog();
            },
          );
      }
    },
    onClose() {
      this.$validator.reset();
      this.loaders.submit = false;
      this.dialog = false;
    },
    closeDialog() {
      this.$validator.reset();
      this.loaders.submit = false;
      this.dialog = false;
    },
  },
};
</script>
