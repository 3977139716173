/* eslint-disable global-require */
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VeeValidate from 'vee-validate';
import Crypto from '@plugins/crypto';
import vuetify from '@plugins/vuetify';
import axios from '@plugins/axios';
import CountryFlag from 'vue-country-flag';
import i18n from './i18n/i18n';
import Application from './Application.vue';
import store from './store';
import router from './router';
import Filters from './filters';

require('@/assets/common/stylus/custom.css');

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(Crypto);
Vue.use(Filters);
Vue.use(VeeValidate, { locale: process.env.VUE_LOCALE });
Vue.component('country-flag', CountryFlag);

// create global event bus
Vue.prototype.$eventHub = new Vue();

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: (h) => h(Application),
}).$mount('#app');
