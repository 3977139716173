<template>
  <v-row justify-center>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="700px"
      height="100vh"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-btn
            icon
            dark
            @click.native="closeDialog()"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-icon>directions_car</v-icon>
            <template v-if="isEdit">
              {{ $t('edit_license_plate') }}
            </template>
            <template v-else>
              {{ $t('add_license_plate') }}
            </template>
          </v-toolbar-title>
        </v-toolbar>
        <v-progress-linear
          class="no-margin"
          :indeterminate="loading"
        />
        <v-card-text>
          <v-container grid-list-md>
            <v-form
              ref="form"
              v-model="form.valid"
              :lazy-validation="true"
            >
              <v-row wrap>
                <v-col class="col-12 col-sm-12 col-md-12">
                  <v-text-field
                    v-model="number"
                    prepend-icon="directions_car"
                    :label="$t('license_plate_number')"
                    :rules="form.validationRules.notEmpty"
                    required
                    :validate-on-blur="form.validateOnBlur"
                  />
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col class="col-12 col-sm-12 col-md-12">
                  <v-text-field
                    v-model="name"
                    prepend-icon="notes"
                    :label="$t('license-plate.name')"
                    :rules="form.validationRules.notEmpty"
                    required
                    :validate-on-blur="form.validateOnBlur"
                  />
                  <small>*{{ $t('field_is_required') }}</small>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            :disabled="loaders.submit"
            text
            @click.native="closeDialog"
          >
            {{ $t('actions.return_to_list') }}
          </v-btn>
          <template v-if="isEdit">
            <v-btn
              color="primary"
              :loading="loaders.submit"
              @click.native="edit"
            >
              {{ $t('edit_license_plate') }}
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="primary"
              :loading="loaders.submit"
              @click.native="submit"
            >
              {{ $t('add_license_plate') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from '@plugins/axios';

export default {
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loaders: {
        submit: false,
      },
      name: '',
      number: '',
      form: {
        validateOnBlur: true,
        valid: true,
        validationRules: {
          notEmpty: [
            (v) => !!v || this.$t('field_is_required'),
          ],
        },
      },
    };
  },
  computed: {
    isEdit() {
      return this.id !== null;
    },
  },
  watch: {
    dialog(value) {
      if (value === true && this.id !== null) {
        this.clearForm();
        this.getPlateData();
      }
    },
  },
  methods: {
    submit() {
      this.form.validateOnBlur = false;
      if (this.$refs.form.validate()) {
        this.loaders.submit = true;
        axios
          .post('/api/users/license_plates', {
            name: this.name,
            number: this.number,
          })
          .then(
            (response) => {
              if (response.status === 200) {
                this.closeDialog();
                this.$eventHub.$emit(
                  'user-list-refresh',
                  response.data,
                );
              }
              this.closeDialog();
            },
            (error) => {
              if (error.request && error.request.status === 400) {
                this.$eventHub.$emit(
                  'show_application_snackbar',
                  'warning',
                  this.$t('no_permissions'),
                );
              }
              // on error
              this.closeDialog();
            },
          );
      }
    },
    edit() {
      this.form.validateOnBlur = false;
      if (this.$refs.form.validate()) {
        this.loaders.submit = true;
        axios
          .patch(`/api/users/license_plates/${this.id}`, {
            name: this.name,
            number: this.number,
          })
          .then(
            (response) => {
              if (response.status === 200) {
                this.closeDialog();
                this.$eventHub.$emit(
                  'user-list-refresh',
                  response.data,
                );
              }
              this.closeDialog();
            },
            (error) => {
              if (error.request && error.request.status === 400) {
                this.$eventHub.$emit(
                  'show_application_snackbar',
                  'warning',
                  this.$t('no_permissions'),
                );
              }
              // on error
              this.closeDialog();
            },
          );
      }
    },
    getPlateData() {
      this.loading = true;
      axios.get(
        `/api/users/license_plates/${this.id}`,
      )
        .then((response) => {
          if (response.data) {
            this.name = response.data.name;
            this.number = response.data.number;
            this.loading = false;
          }
          this.loading = false;
        });
    },
    clearForm() {
      this.name = '';
      this.number = '';
    },
    closeDialog() {
      this.clearForm();
      this.$validator.reset();
      this.loaders.submit = false;
      this.dialog = false;
    },
  },
};
</script>
