<template>
  <v-card
    class="px-3 py-3"
    outlined
    color="blue-grey lighten-5"
  >
    <v-row>
      <v-col
        cols="6"
      >
        <h3 class="mb-3">
          {{ headerText }}
        </h3>
      </v-col>
      <v-col
        cols="6"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              class="float-right"
              elevation="1"
              tile
              v-bind="attrs"
              color="primary"
              x-small
              fab
              v-on="on"
              @click.native="openEditModal(userId)"
            >
              <v-icon color="white">
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('users.edit-limit') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <edit-user-limits-modal
      :key="`EditUserModal${userId}`"
      :ref="`EditUserModal${userId}`"
      :user-id="userId"
    />
    <v-simple-table
      v-if="items.length"
      dense
      class="elevation-3 py-2"
    >
      <template #default>
        <tbody>
          <tr>
            <td class="border-right" />
            <td
              v-for="[key, header] in headers"
              :key="`headers-${key}`"
              class="border-right font-weight-bold"
            >
              {{ $t(`users.limit.${header}`) }}
            </td>
          </tr>
          <tr
            v-for="[key, value] in items"
            :key="`item-${key}`"
          >
            <td
              :key="`itemName-${key}`"
              class="border-right text-left font-weight-bold"
            >
              {{ $t(`users.limit.${key}`) }}
            </td>
            <template
              v-for="[key2, arrayItemValue] in Object.entries(value)"
            >
              <td
                :key="`item-${key}-item-array-value-${key2}`"
                class="border-right"
              >
                <template v-if="isNumberParsedAsString(arrayItemValue)">
                  {{ arrayItemValue|customCurrency(currencyCode) }}
                </template>
                <template v-else>
                  {{ arrayItemValue ? arrayItemValue : '-' }}
                </template>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-text v-else>
      <v-icon small>
        mdi-alert-circle-outline
      </v-icon>
      <i class="ml-2">{{ $t('no_data') }}</i>
    </v-card-text>
  </v-card>
</template>

<script>
import NumericValueMixin from '@components/mixins/NumericValueMixin.vue';
import EditUserLimitsModal from '@components/users/EditUserLimitsModal.vue';

export default {
  name: 'LimitTable',
  components: {
    EditUserLimitsModal,
  },
  mixins: [
    NumericValueMixin,
  ],
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    currencyCode: {
      type: String,
      default: undefined,
      required: false,
    },
    headerText: {
      type: String,
      default: 'Limit',
      required: false,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    headers() {
      if (typeof this.data === 'undefined') {
        return [];
      }

      const firstKey = Object.keys(this.data)[0];

      if (typeof firstKey === 'undefined') {
        return [];
      }

      return Object.entries(Object.keys(this.data[firstKey]));
    },
    items() {
      if (typeof this.data === 'undefined') {
        return [];
      }

      return Object.entries(this.data);
    },
  },
  methods: {
    openEditModal(editUserId) {
      this.$refs[`EditUserModal${editUserId}`].dialog = true;
    },
  },
};
</script>
