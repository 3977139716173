<template>
  <v-layout
    row
    justify-center
  >
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-btn
            icon
            dark
            @click.native="dialog = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-icon>business</v-icon>
            {{ $t('invoice_data') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              text
              :disabled="loading"
              @click="submit"
            >
              {{ $t('navigation.save') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-progress-linear
          class="no-margin"
          :indeterminate="loading"
        />
        <v-card-text>
          <form>
            <v-text-field
              v-model="email"
              v-validate="'required|email|max:128'"
              :label="$t('email')"
              :counter="128"
              :error-messages="errors.collect('email')"
              data-vv-name="email"
              :data-vv-as="$t('email')"
              :disabled="loading"
              required
            />
            <v-text-field
              v-model="nip"
              v-validate="'required|max:20'"
              :label="$t('tax_id')"
              :error-messages="errors.collect('nip')"
              data-vv-name="nip"
              :data-vv-as="$t('tax_id')"
              :disabled="loading"
              required
              @change="getTaxPayerInfo"
            />
            <v-text-field
              v-model="name"
              v-validate="'required|max:128'"
              :label="$t('invoices.name')"
              :counter="128"
              :error-messages="errors.collect('name')"
              data-vv-name="name"
              :data-vv-as="$t('invoices.name')"
              :disabled="loading"
              required
            />
            <v-text-field
              v-model="address"
              v-validate="'required|max:255'"
              :label="$t('invoices.address')"
              :counter="255"
              :error-messages="errors.collect('address')"
              data-vv-name="address"
              :data-vv-as="$t('invoices.address')"
              :disabled="loading"
              required
            />
            <v-text-field
              v-model="postcode"
              v-validate="'required|max:12'"
              :label="$t('zip_code')"
              :counter="12"
              :error-messages="errors.collect('postcode')"
              data-vv-name="postcode"
              :data-vv-as="$t('zip_code')"
              :disabled="loading"
              required
            />
            <v-text-field
              v-model="city"
              v-validate="'required|alpha_spaces|max:128'"
              :label="$t('city')"
              :counter="128"
              :error-messages="errors.collect('city')"
              data-vv-name="city"
              :data-vv-as="$t('city')"
              :disabled="loading"
              required
            />
            <v-select
              v-model="country"
              v-validate="'required'"
              :items="countries"
              item-text="name"
              item-value="short_name"
              :label="$t('country')"
              :error-messages="errors.collect('country')"
              data-vv-name="country"
              :data-vv-as="$t('country')"
              :disabled="loading"
              :autocomplete="true"
              required
            />
            <v-switch
              v-model="invoiced_after_transaction"
              :label="$t('invoice.invoiced_after_transaction')"
              :disabled="disabledSwitches.invoicedAfterTransaction"
            />
            <div class="text-sm-start">
              <v-btn
                color="primary"
                @click="close"
              >
                {{ $t('actions.close') }}
              </v-btn>
              <v-btn
                :disabled="loading"
                @click="submit"
              >
                {{ $t('navigation.save') }}
              </v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
      >
        {{ snackbarText }}
      </v-snackbar>
    </div>
  </v-layout>
</template>

<script>
import common from '@components/financial/common.js';
import axios from '@plugins/axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      snackbar: false,
      dataExists: false,
      snackbarText: '',
      snackbarColor: '',
      toastTimeout: common.toastTimeout,
      disabledSwitches: {
        invoicedAfterTransaction: (
          this.$store.state.auth.user.fleet_manager
          || this.$store.state.auth.user.trusted_partner) === 1,
      },
      email: '',
      nip: '',
      tax_countries: [
        'PL',
        'CS',
        'EN',
        'FR',
      ],
      name: '',
      address: '',
      postcode: '',
      city: '',
      province: '',
      country: '',
      currencyCode: null,
      invoiced_after_transaction: true,
      countries: [],
      termsOfUseAccepted: this.$store.state.auth.user.terms,
    };
  },
  computed: {
    ...mapGetters({
      taxPayerInfo: 'branding/tax_payer_info',
    }),
    currency() {
      return this.currencyCode || this.$store.state.auth.user.currency.code;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.loading = true;
        this.getData();
      }
    },
  },
  mounted() {
    this.getCountriesData();
  },
  methods: {
    save() {
      this.loading = true;
      axios.post(
        '/api/users/invoice_data',
        {
          email: this.email,
          nip: this.nip,
          name: this.name,
          address: this.address,
          city: this.city,
          country: { short_name: this.country },
          currency: this.currency,
          post_code: this.postcode,
          invoiced_after_transaction: this.invoiced_after_transaction,
        },
      )
        .then(() => {
          this.showToast('success', this.$i18n.t('alert.changes_saved'));
          this.loading = false;
          this.dialog = false;
        }, (error) => {
          if (error.response.status === 400) {
            if (!Number.isInteger(this.nip)) {
              this.showToast(
                'error',
                this.$i18n.t('alert.nip_number_incorrect'),
              );
            }
            this.loading = false;
          }
        });
    },
    getData() {
      this.loading = true;
      this.$validator.errors.clear();
      axios.get('/api/users/invoice_data')
        .then((response) => {
          if (response.status === 204 && this.$store.state.auth.user.fleet_member !== true) {
            this.showToast('info', this.$i18n.t('alert.fill_invoice_data'));
            // uncomment below line to show invoice data dialog on login
            // this.dialog = true;
          } else if (response.status === 200) {
            const resData = response.data;
            this.email = resData.email;
            this.address = resData.address;
            this.city = resData.city;
            this.country = resData.country ? resData.country.short_name : null;
            this.currencyCode = resData.invoice_currency?.code || null;
            this.name = resData.name;
            this.postcode = resData.post_code;
            this.nip = resData.nip;
            this.invoiced_after_transaction = resData.invoiced_after_transaction;
            this.dataExists = true;

            if (this.$store.state.auth.user.trusted_partner) {
              this.invoiced_after_transaction = true;
            }
          }
          this.loading = false;
        });
    },
    getCountriesData() {
      const countriesData = axios.get('/api/users/countries');
      countriesData.then((response) => {
        if (response.status === 200) {
          const resData = response.data;
          this.countries = resData;
        }
      });

      return countriesData;
    },
    submit() {
      this.$validator.validateAll()
        .then((result) => {
          if (result) {
            this.save();
          }
        });
    },
    close() {
      this.dialog = false;
    },
    showToast(color, message) {
      this.snackbarColor = color;
      this.snackbarText = message;
      this.snackbar = true;
    },
    getTaxPayerInfo() {
      if (this.taxPayerInfo) {
        this.loading = true;
        const nipDigital = this.nip.replace(/[^\d]/g, '');
        axios.get(`/api/users/invoice_data/find?nip=${nipDigital}`)
          .then((response) => {
            if (response.status === 200) {
              const resData = response.data;
              this.address = resData.address;
              this.city = resData.city;
              this.country = resData.country ? resData.country.short_name : null;
              this.name = resData.name;
              this.postcode = resData.post_code;
              this.nip = resData.nip;
              this.dataExists = true;
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.showToast('info', this.$i18n.t('alert.no_data_in_ceidg'));
              this.dialog = true;
            }
          });
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="stylus">
.dialog
  position: absolute

.dialog__content
  position: absolute
</style>
