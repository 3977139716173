<template>
  <div style="padding-left: 20px; padding-right: 20px;">
    <v-layout
      justify-end
      align-center
    >
      <v-row>
        <v-col
          cols="6"
          md="4"
        >
          <multiselect
            v-model="topUpIssuer"
            :items="issuersDisabled"
            :label="filters.issuers.text"
            :prepend-icon="filters.issuers.icon"
            :return-array="false"
            :disabled="loader"
            unified
            single
            allow-null
          />
        </v-col>
        <v-col
          col="4"
          md="8"
        >
          <v-layout
            class="pt-2"
            justify-end
          >
            <v-btn
              text
              small
              fab
              color="green"
              class="mt-0"
              @click="getData"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="float-right ml-2"
              @click="$refs.AddTopUpModal.dialog = true"
            >
              <v-icon>add</v-icon>
              {{ $t('top_up.top_up_account') }}
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </v-layout>
    <v-data-table
      mobile-breakpoint="0"
      :headers="dataTable.headers"
      :items="dataTable.items"
      item-key="id"
      :loading="loader"
      :options.sync="pagination"
      :server-items-length="dataTable.totalItems"
      :footer-props="filtering.footerProps"
      :single-expand="true"
      class="transparent"
    >
      <template #progress>
        <v-progress-linear
          indeterminate
          color="primary"
        />
      </template>

      <template #item="{ item, isExpanded, expand }">
        <template v-if="!loader">
          <tr @click="expand(!isExpanded)">
            <td class="text-start border-right">
              <template v-if="null !== item.confirmed_timestamp">
                {{ item.confirmed_timestamp }}
              </template>
            </td>
            <td class="text-center border-right">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ getTopUpProgressIcon(item.transaction_type).icon }}
                  </v-icon>
                </template>
                <span>{{ getTopUpProgressIcon(item.transaction_type).toltipText }}</span>
              </v-tooltip>
            </td>
            <td class="text-end">
              {{ item.bkfpay_user.email }}
            </td>
            <td class="text-end border-right">
              {{ item.bkfpay_company.email }}
            </td>
            <td class="text-end border-right">
              {{ $n(item.value, item.currency.code) }}
            </td>
            <td
              class="text-end"
            >
              <template v-if="item.document !== null">
                <btn-download
                  v-if="item.document.type === 'receipt'
                    || item.document.type === 'invoice'"
                  x-small
                  :text="getDocumentTypeName(item.document.type)"
                  :tile="true"
                  @click="documentDownload(item)"
                />
              </template>
              <template v-if="showDetails(item)">
                <v-icon v-if="isExpanded">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else>
                  mdi-chevron-down
                </v-icon>
              </template>
            </td>
          </tr>
        </template>
      </template>

      <template #expanded-item="{ headers, item }">
        <td
          v-if="!loader && showDetails(item)"
          :colspan="headers.length"
        >
          <v-card
            flat
            class="details pa-2"
          >
            <h4
              class="pt-3"
            >
              {{ $t('transactions.details') }}
            </h4>
            <v-simple-table
              dense
              class="elevation-3 ma-4 pa-4"
            >
              <tbody>
                <tr v-if="item.license_plate !== null">
                  <td
                    class="border-right"
                  >
                    {{ $t('license_plate_number') }}
                  </td>
                  <td>
                    {{ item.license_plate }}
                  </td>
                </tr>
                <tr v-if="item.external_payment !== null">
                  <td
                    class="border-right"
                  >
                    {{ $t('table.external_type') }}
                  </td>
                  <td>
                    {{ item.external_payment.issuer }}
                  </td>
                </tr>
                <tr v-if="item.external_payment !== null">
                  <td
                    class="border-right"
                  >
                    {{ $t('table.external_value') }}
                  </td>
                  <td>
                    {{ $n(item.external_payment.value, item.external_payment.currency.code) }}
                  </td>
                </tr>
                <tr v-if="item.document !== null">
                  <td
                    class="border-right"
                  >
                    {{ $t('document') }}
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.document.number }}
                          <v-icon>
                            {{ getDocumentTypeIcon(item.document.type) }}
                          </v-icon>
                        </div>
                      </template>
                      <span>
                        {{ getDocumentTypeName(item.document.type) }}
                      </span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr v-if="item.carwash !== null">
                  <td class="border-right">
                    {{ $t('form.carwash') }}
                  </td>
                  <td>
                    {{ item.carwash.long_name }}
                  </td>
                </tr>
                <tr v-if="item.stand_code !== null">
                  <td class="border-right">
                    {{ $t('stand') }}
                  </td>
                  <td>
                    {{ item.stand_code }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <add-top-up-modal ref="AddTopUpModal" />
  </div>
</template>

<script>

import SalesDocumentMixin from '@components/mixins/SalesDocumentMixin.vue';
import BtnDownload from '@/common/BtnDownload.vue';
import ExportMixin from '@components/mixins/ExportMixin.vue';
import multiselect from '@/common/filters/Multiselect.vue';
import AddTopUpModal from '@components/topupsValue/AddTopUpModal.vue';

export default {
  components: {
    BtnDownload,
    multiselect,
    AddTopUpModal,
  },
  mixins: [
    SalesDocumentMixin,
    ExportMixin,
  ],
  data() {
    return {
      loader: true,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      possibleIssuers: [
      ],
      filters: {
        issuers: {
          texts: {
            VIRTUAL_CARD: this.$t('issuer_virtual_card'),
            BKFPAY: this.$t('transactions.issuer_type.bkfpay'),
            EXTERNAL_PAYMENT: this.$t('external_payment'),
          },
          icons: {
            VIRTUAL_CARD: 'mdi-credit-card',
            BKFPAY: 'mdi-cellphone-wireless',
            EXTERNAL_PAYMENT: 'mdi-bank-transfer-in',
          },
          text: this.$t('table.account_type'),
          icon: 'mdi-wallet',
        },
      },
      topUpIssuer: null,
      filtering: {
        footerProps: {
          'items-per-page-options': [25, 50, 100],
          'items-per-page-text': this.$t('table.rows_per_page'),
        },
        search: {
          dateFrom: null,
          dateTo: null,
          hourFrom: null,
          hourTo: null,
        },
        nameExists: null,
      },
      dataTable: {
        headers: [
          {
            text: this.$t('table.date'),
            value: 'confirmed_timestamp',
            displayMethod: 'date',
            sortable: false,
          },
          {
            text: this.$t('table.transaction_type'),
            value: 'transaction_type',
            class: 'text-center',
            sortable: false,
          },
          {
            text: this.$t('table.user'),
            value: 'bkfpay_user',
            class: 'text-end',
            sortable: false,
          },
          {
            text: this.$t('table.account'),
            value: 'bkfpay_company',
            class: 'text-end',
            sortable: false,
          },

          {
            text: this.$t('table.transaction_value'),
            value: 'payment_value',
            class: 'text-end',
            sortable: false,
          },
          {
            text: '',
            value: 'expandable',
            class: 'text-end',
            sortable: false,
          },
        ],
        items: [],
        totalItems: 0,
      },
    };
  },
  computed: {
    issuersDisabled() {
      const issuers = this.possibleIssuers.map(
        (row) => ({
          text: this.filters.issuers.texts[row.name] ?? row.name,
          value: row.name,
          disabled: false,
          icon: this.filters.issuers.icons[row.name] ?? '',
        }),
      );

      return issuers;
    },
  },
  watch: {
    topUpIssuer() {
      this.pagination.page = 1;
      this.getData();
    },
    filtering: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    pagination: {
      handler(newValue, oldValue) {
        if (oldValue.sortDesc !== newValue.sortDesc
            || oldValue.sortBy !== newValue.sortBy
            || oldValue.itemsPerPage !== newValue.itemsPerPage
        ) {
          // eslint-disable-next-line no-param-reassign
          newValue.page = 1;
        }
        this.getData();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.params.top_up_id !== undefined) {
      this.$refs.AddTopUpModal.dialog = true;
    }
  },
  created() {
    this.getData();
    this.$eventHub.$on('application-refresh-top-up-list', this.getData);
  },
  beforeDestroy() {
    // destroy events hooks
    this.$eventHub.$off('application-refresh-top-up-list');
  },
  methods: {
    getData() {
      this.loader = true;

      this.axios.get(
        '/api/users/transactions',
        {
          params: {
            issuer: this.topUpIssuer,
            pageNumber: this.pagination.page,
            itemsPerPage: this.pagination.itemsPerPage,
            dateFrom: this.filtering.search.dateFrom
              ? `${this.filtering.search.dateFrom}`
              : null,
            dateTo: this.filtering.search.dateTo
              ? `${this.filtering.search.dateTo}`
              : null,
          },
        },
      )
        .then((response) => {
          if ((response.status === 200) && response.data) {
            this.dataTable.totalItems = Number(response.data.totalItems);
            this.dataTable.items = response.data.items;
            this.possibleIssuers = response.data.issuers;
            this.loader = false;
          }
        });
    },
    documentDownload(item) {
      if (item.document.type === 'invoice') {
        this.invoiceDownload(item);
      }

      if (item.document.type === 'receipt') {
        this.receiptDownload(item);
      }
    },
    invoiceDownload(item) {
      const url = `/api/users/invoice/download/${item.document.id}`;
      const filename = `${item.document.number}.pdf`;
      this.onExport({
        url,
        filetype: 'application/pdf',
        filename,
      });
    },
    receiptDownload(item) {
      const url = `/api/users/receipt/download/${item.document.id}`;
      const filename = `${item.document.number}.png`;
      this.onExport({
        url,
        filetype: 'image/png',
        filename,
      });
    },
    showDetails(item) {
      if (item.license_plate !== null) {
        return true;
      }
      if (item.external_payment !== null) {
        return true;
      }
      if (item.document !== null) {
        return true;
      }
      if (item.carwash !== null) {
        return true;
      }
      if (item.stand_code !== null) {
        return true;
      }

      return false;
    },
    getTopUpProgressIcon(type) {
      if (type === 'TOP_UP') {
        return {
          icon: 'mdi-trending-up',
          toltipText: this.$t('top_up.top_up_account'),
        };
      }
      if (type === 'PAYMENT') {
        return {
          icon: 'mdi-trending-down',
          toltipText: this.$t('table.payment'),
        };
      }

      if (type === 'TOP_UP_BONUS') {
        return {
          icon: 'mdi-sale',
          toltipText: this.$t('table.topup_bonus'),
        };
      }
      if (type === 'TOP_UP_CODE') {
        return {
          icon: 'mdi-card-plus',
          toltipText: this.$t('table.topup_code'),
        };
      }

      return {
        icon: 'question',
        toltipText: this.$t('unknown'),
      };
    },
  },
};
</script>

<style lang="css" scoped>

.details {
  background-color: rgb(236, 239, 241)
}

</style>
