<template>
  <v-content
    class="fill-height bg-image"
  >
    <v-container
      fluid
      justify-center
      fill-height
    >
      <v-container>
        <v-row
          class="flex-column"
          align="center"
          justify="center"
        >
          <div class="bl-logo__wrapper">
            <v-img
              class="bl-logo py-4"
              contain
            />
          </div>
          <v-alert
            transition="slide-y-transition"
            :value="!!message.text"
            :type="message.type"
          >
            {{ message.text }}
          </v-alert>
          <v-card
            class="d-block w-100 mb-7"
            width="650"
            elevation="6"
            raised
            outlined
          >
            <v-tabs-items v-model="tab">
              <!--Login-->
              <v-tab-item
                key="resetTab"
              >
                <v-card-title>
                  <v-row>
                    <v-col>
                      {{ $t('login.password_reset') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-form
                  ref="resetForm"
                  @submit.prevent="onResetSubmit()"
                >
                  <v-card-text class="py-0">
                    <v-text-field
                      v-model="reset.password"
                      prepend-icon="mdi-key"
                      :rules="[rules.required, rules.min]"
                      type="password"
                      :label="$t('password')"
                      required
                    />
                    <v-text-field
                      v-model="reset.passwordRepeat"
                      prepend-icon="mdi-key-change"
                      :rules="[rules.required, rules.min, rules.passwordSame]"
                      type="password"
                      :label="$t('repeat_password')"
                      required
                    />
                  </v-card-text>
                  <v-card-text class="pt-0 justify-center align-center text-center">
                    <v-btn
                      color="primary"
                      :loading="loading"
                      @click="onResetSubmit()"
                    >
                      {{ $t('login.register') }}
                      <v-icon
                        right
                        dark
                      >
                        send
                      </v-icon>
                    </v-btn>
                  </v-card-text>
                </v-form>
              </v-tab-item>

              <!--Reset-->
              <v-tab-item
                key="new-password-success"
              >
                <v-card-title>
                  <v-row>
                    <v-col class="justify-center align-center text-center">
                      <p class="word-break-normal">
                        {{ $t('new_password.messages.success') }}
                      </p>
                      <v-btn
                        color="primary"
                        @click="goToLoginPage()"
                      >
                        {{ $t('new_password.go_to_login') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-row>
      </v-container>
    </v-container>
  </v-content>
</template>

<script>
import axios from '@plugins/axios';

export default {
  data() {
    return {
      token: this.$route.params.token_a,
      loading: false,
      tab: 0,
      email: '',
      message: {
        type: 'error',
        text: null,
      },
      messages: {
        empty: {
          type: 'error',
          text: null,
        },
        newPasswordSuccess: {
          type: 'success',
          text: this.$t('new_password.messages.success'),
        },
        newPasswordFailure: {
          type: 'error',
          text: this.$t('new_password.messages.fail'),
        },
      },
      reset: {
        password: '',
        passwordRepeat: '',
      },
      mobileStoreSpeedDialOpen: false,
      rules: {
        required: (v) => !!v || this.$t('field_is_required'),
        min: (v) => v.length >= 6 || this.$t('form.validation.min'),
        passwordSame: (v) => v === this.reset.password || this.$t('form.validation.password_same'),
        email: (v) => !!v.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) || this.$t('form.validation.email'),
      },
    };
  },
  computed: {
    mobileStoreSpeedDialDirection() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'right';
      }
      return 'top';
    },
  },
  methods: {
    goToLoginPage() {
      this.$router.push('/login');
    },
    onResetSubmit() {
      const isFormValid = true;

      // /reset-password
      if (isFormValid) {
        this.loading = true;
        axios.post('api/users/new-password', {
          plainPassword: this.reset.password,
          token: this.token,
          lang: process.env.VUE_APP_I18N_LOCALE,
        }).then((response) => {
          if (response.data.status === false) {
            return Promise.reject(response);
          }
          this.loading = false;
          this.tab = 1;
          this.message = { ...this.messages.empty };

          return true;
        }).catch((err) => {
          this.loading = false;
          this.message = { ...this.messages.newPasswordFailure };
          this.message.text += this.$t(err.data.message);
        });
      }
      return false;
    },
  },
};
</script>
