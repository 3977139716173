import Vue from 'vue';
import VueRouter from 'vue-router';
import TransactionsPage from '@pages/TransactionsPage.vue';
import InvoicesPage from '@pages/InvoicesPage.vue';
import UsersPage from '@pages/UsersPage.vue';
import LicensePlates from '@pages/LicensePlatesPage.vue';
import LoginPage from '@pages/LoginPage.vue';
import ResetPage from '@pages/ResetPage.vue';
import SaveToken from '@pages/SaveToken.vue';
import Success from '@pages/SuccessPage.vue';
import Error from '@pages/ErrorPage.vue';
import RegisterStatus from '@pages/RegisterStatus.vue';
import store from '@store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/save_token/:login_token',
    name: 'save_token',
    component: SaveToken,
  },
  {
    path: '/reset/:token_a',
    name: 'reset',
    component: ResetPage,
  },
  {
    path: '/#/_=_',
    redirect: { name: 'transactions' },
  },
  {
    path: '/_=_',
    redirect: { name: 'transactions' },
  },
  {
    path: '/',
    redirect: { name: 'transactions' },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: TransactionsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
    meta: { requiresAuth: true },
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: { requiresAuth: true },
  },
  {
    path: '/top-up/:top_up_id',
    component: TransactionsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: InvoicesPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/map',
    name: 'map',
    component: Map,
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/license-plates',
    name: 'license_plates',
    component: LicensePlates,
    meta: { requiresAuth: true },
  },
  {
    path: '/users/register-status/:success',
    name: 'register-status',
    component: RegisterStatus,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      next();
      return;
    }
    next('/login');
  } else {
    // fix of redirecting to login if user is already login
    if (store.getters['auth/isLoggedIn'] && to.name === 'login') {
      next('/transactions');
      return;
    }
    next();
  }
});

export default router;
