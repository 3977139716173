<template>
  <v-card
    class="px-3 py-3"
    outlined
    color="blue-grey lighten-5"
  >
    <h3 class="mb-3">
      {{ headerText }}
    </h3>
    <v-simple-table
      v-if="items.length"
      dense
      class="elevation-3 py-2"
    >
      <template #default>
        <tbody>
          <tr>
            <td
              v-for="[key, header] in headers"
              :key="`headers-${key}`"
              class="border-right font-weight-bold"
            >
              {{ $t(`users.stat.${header}`) }}
            </td>
          </tr>
          <tr
            v-for="[key, value] in items"
            :key="`item-${key}`"
          >
            <template
              v-for="[key2, arrayItemValue] in Object.entries(value)"
            >
              <td
                :key="`item-${key}-item-array-value-${key2}`"
                class="border-right "
              >
                <template v-if="isNumberParsedAsString(arrayItemValue) && key2 !== 'count'">
                  {{ arrayItemValue|customCurrency(currencyCode) }}
                </template>
                <template v-else-if="key2 === 'issuer' || key2 === 'type'">
                  <v-icon v-show="arrayItemValue">
                    {{ icons[arrayItemValue] }}
                  </v-icon>
                  {{ arrayItemValue ? texts[arrayItemValue] : '-' }}
                </template>
                <template v-else>
                  {{ arrayItemValue ? arrayItemValue : '-' }}
                </template>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-text v-else>
      <v-icon small>
        mdi-alert-circle-outline
      </v-icon>
      <i class="ml-2">{{ $t('no_data') }}</i>
    </v-card-text>
  </v-card>
</template>

<script>
import NumericValueMixin from '@components/mixins/NumericValueMixin.vue';

export default {
  name: 'StatsTable',
  mixins: [
    NumericValueMixin,
  ],
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
    currencyCode: {
      type: String,
      default: undefined,
      required: false,
    },
    headerText: {
      type: String,
      default: 'Stats',
      required: false,
    },
  },
  data() {
    return {
      texts: {
        VIRTUAL_CARD: this.$t('issuer_virtual_card'),
        BKFPAY: this.$t('transactions.issuer_type.bkfpay'),
        EXTERNAL_PAYMENT: this.$t('external_payment'),
        PAYMENT: this.$t('table.payment'),
        TOP_UP: this.$t('top_up.top_up_account'),
        TOP_UP_BONUS: this.$t('table.topup_bonus'),
        TOP_UP_CODE: this.$t('table.topup_code'),
      },
      icons: {
        VIRTUAL_CARD: 'mdi-credit-card',
        BKFPAY: 'mdi-cellphone-wireless',
        EXTERNAL_PAYMENT: 'mdi-bank-transfer-in',
        PAYMENT: 'mdi-trending-down',
        TOP_UP: 'mdi-trending-up',
        TOP_UP_BONUS: 'mdi-sale',
        TOP_UP_CODE: 'mdi-card-plus',
      },
    };
  },
  computed: {
    headers() {
      if (typeof this.data === 'undefined') {
        return [];
      }

      const firstKey = Object.keys(this.data)[0];

      if (typeof firstKey === 'undefined') {
        return [];
      }

      return Object.entries(Object.keys(this.data[firstKey]));
    },
    items() {
      if (typeof this.data === 'undefined' || this.data === null) {
        return [];
      }

      return Object.entries(this.data);
    },
  },
  methods: {
    getTopUpProgressIcon(type) {
      if (type === 'TOP_UP') {
        return {
          icon: 'mdi-trending-up',
          toltipText: this.$t('top_up.top_up_account'),
        };
      }
      if (type === 'PAYMENT') {
        return {
          icon: 'mdi-trending-down',
          toltipText: this.$t('table.payment'),
        };
      }

      if (type === 'TOP_UP_BONUS') {
        return {
          icon: 'mdi-sale',
          toltipText: this.$t('table.topup_bonus'),
        };
      }
      if (type === 'TOP_UP_CODE') {
        return {
          icon: 'mdi-card-plus',
          toltipText: this.$t('table.topup_code'),
        };
      }

      return {
        icon: 'question',
        toltipText: this.$t('unknown'),
      };
    },
  },
};
</script>
