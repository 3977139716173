<template>
  <v-row justify-center>
    <v-dialog
      :key="`EditUserDialog${userId}`"
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="700px"
      height="100vh"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          flat
        >
          <v-btn
            icon
            dark
            @click.native="closeDialog()"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-icon>account_balance_wallet</v-icon>
            {{ $t('users.edit-limit') }}: {{ user.email ? user.email : '-' }}
          </v-toolbar-title>
        </v-toolbar>
        <v-progress-linear
          class="no-margin"
          :indeterminate="loaders.site"
        />
        <v-card-text>
          <v-container grid-list-md>
            <v-form
              ref="form"
              v-model="form.valid"
              :lazy-validation="true"
            >
              <v-row wrap>
                <v-col class="col-12 col-sm-12 col-md-12">
                  <v-text-field
                    v-model="user.settings.limit_hourly"
                    type="number"
                    prepend-icon="mdi-clock-outline"
                    :label="$t('users.limit.hourly')"
                    :rules="form.validationRules.positiveNumber"
                    :validate-on-blur="form.validateOnBlur"
                    :disabled="loaders.site"
                  />
                  <v-text-field
                    v-model="user.settings.limit_daily"
                    type="number"
                    prepend-icon="mdi-calendar-today"
                    :label="$t('users.limit.daily')"
                    :rules="form.validationRules.positiveNumber"
                    :validate-on-blur="form.validateOnBlur"
                    :disabled="loaders.site"
                  />
                  <v-text-field
                    v-model="user.settings.limit_monthly"
                    type="number"
                    prepend-icon="mdi-calendar-month"
                    :label="$t('users.limit.monthly')"
                    :rules="form.validationRules.positiveNumber"
                    :validate-on-blur="form.validateOnBlur"
                    :disabled="loaders.site"
                  />
                  <small>*{{ $t('field_is_required') }}</small>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            :disabled="loaders.submit"
            text
            @click.native="closeDialog"
          >
            {{ $t('actions.return_to_list') }}
          </v-btn>
          <v-btn
            color="primary"
            :loading="loaders.submit"
            @click.native="submit"
          >
            {{ $t('navigation.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from '@plugins/axios';

export default {
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: {
        settings: {
          limit_daily: null,
          limit_hourly: null,
          limit_monthly: null,
        },
      },
      dialog: false,
      loaders: {
        site: true,
        submit: false,
      },
      email: '',
      form: {
        validateOnBlur: true,
        valid: true,
        validationRules: {
          positiveNumber: [
            (v) => (Number(v) > 0 || v === '' || v === null) || this.$t('users.limit-validation-message'),
          ],
        },
      },
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getData();
      } else {
        this.closeDialog();
      }
    },
  },
  methods: {
    getData() {
      this.loaders.site = true;
      axios
        .get(`/api/users/fleet/users/${this.userId}`)
        .then(
          (response) => {
            if (response.status === 200) {
              this.user = response.data;

              if (this.user.settings === null) {
                this.user.settings = {
                  limit_hourly: null,
                  limit_daily: null,
                  limit_monthly: null,
                };
              }
              this.loaders.site = false;
            }
          },
        );
    },
    submit() {
      this.form.validateOnBlur = false;
      if (this.$refs.form.validate()) {
        this.loaders.submit = true;
        this.loaders.site = true;
        axios
          .post(`/api/users/fleet/users/${this.userId}/settings`, {
            limit_hourly: parseFloat(this.user.settings.limit_hourly),
            limit_daily: parseFloat(this.user.settings.limit_daily),
            limit_monthly: parseFloat(this.user.settings.limit_monthly),
          })
          .then(
            (response) => {
              if (response.status === 200) {
                this.closeDialog();
                this.$eventHub.$emit(
                  'user-list-refresh',
                  response.data,
                );
              }
              this.$eventHub.$emit(
                'show_application_snackbar',
                'success',
                this.$t('payment_success'),
              );
              this.closeDialog();
            },
            (error) => {
              if (error.request && error.request.status === 409) {
                this.$eventHub.$emit(
                  'show_application_snackbar',
                  'warning',
                  this.$t('errors.user-already-exists'),
                );
              } else if (error.request && error.request.status === 400) {
                this.$eventHub.$emit(
                  'show_application_snackbar',
                  'warning',
                  this.$t('no_permissions'),
                );
              }
              // on error
              this.closeDialog();
            },
          );
      }
    },
    getNullUserValues() {
      return {
        settings: {
          limit_daily: null,
          limit_hourly: null,
          limit_monthly: null,
        },
      };
    },
    closeDialog() {
      this.$validator.reset();
      this.loaders.submit = false;
      this.user = this.getNullUserValues();
      this.dialog = false;
    },
  },
};
</script>
