var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-left":"20px","padding-right":"20px"}},[_c('v-layout',{attrs:{"justify-end":"","align-center":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('multiselect',{attrs:{"items":_vm.issuersDisabled,"label":_vm.filters.issuers.text,"prepend-icon":_vm.filters.issuers.icon,"return-array":false,"disabled":_vm.loader,"unified":"","single":"","allow-null":""},model:{value:(_vm.topUpIssuer),callback:function ($$v) {_vm.topUpIssuer=$$v},expression:"topUpIssuer"}})],1),_c('v-col',{attrs:{"col":"4","md":"8"}},[_c('v-layout',{staticClass:"pt-2",attrs:{"justify-end":""}},[_c('v-btn',{staticClass:"mt-0",attrs:{"text":"","small":"","fab":"","color":"green"},on:{"click":_vm.getData}},[_c('v-icon',[_vm._v("mdi-cached")])],1),_c('v-btn',{staticClass:"float-right ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.AddTopUpModal.dialog = true}}},[_c('v-icon',[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('top_up.top_up_account'))+" ")],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"transparent",attrs:{"mobile-breakpoint":"0","headers":_vm.dataTable.headers,"items":_vm.dataTable.items,"item-key":"id","loading":_vm.loader,"options":_vm.pagination,"server-items-length":_vm.dataTable.totalItems,"footer-props":_vm.filtering.footerProps,"single-expand":true},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(!_vm.loader)?[_c('tr',{on:{"click":function($event){return expand(!isExpanded)}}},[_c('td',{staticClass:"text-start border-right"},[(null !== item.confirmed_timestamp)?[_vm._v(" "+_vm._s(item.confirmed_timestamp)+" ")]:_vm._e()],2),_c('td',{staticClass:"text-center border-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getTopUpProgressIcon(item.transaction_type).icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTopUpProgressIcon(item.transaction_type).toltipText))])])],1),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(item.bkfpay_user.email)+" ")]),_c('td',{staticClass:"text-end border-right"},[_vm._v(" "+_vm._s(item.bkfpay_company.email)+" ")]),_c('td',{staticClass:"text-end border-right"},[_vm._v(" "+_vm._s(_vm.$n(item.value, item.currency.code))+" ")]),_c('td',{staticClass:"text-end"},[(item.document !== null)?[(item.document.type === 'receipt'
                  || item.document.type === 'invoice')?_c('btn-download',{attrs:{"x-small":"","text":_vm.getDocumentTypeName(item.document.type),"tile":true},on:{"click":function($event){return _vm.documentDownload(item)}}}):_vm._e()]:_vm._e(),(_vm.showDetails(item))?[(isExpanded)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])]:_vm._e()],2)])]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [(!_vm.loader && _vm.showDetails(item))?_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"details pa-2",attrs:{"flat":""}},[_c('h4',{staticClass:"pt-3"},[_vm._v(" "+_vm._s(_vm.$t('transactions.details'))+" ")]),_c('v-simple-table',{staticClass:"elevation-3 ma-4 pa-4",attrs:{"dense":""}},[_c('tbody',[(item.license_plate !== null)?_c('tr',[_c('td',{staticClass:"border-right"},[_vm._v(" "+_vm._s(_vm.$t('license_plate_number'))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.license_plate)+" ")])]):_vm._e(),(item.external_payment !== null)?_c('tr',[_c('td',{staticClass:"border-right"},[_vm._v(" "+_vm._s(_vm.$t('table.external_type'))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.external_payment.issuer)+" ")])]):_vm._e(),(item.external_payment !== null)?_c('tr',[_c('td',{staticClass:"border-right"},[_vm._v(" "+_vm._s(_vm.$t('table.external_value'))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$n(item.external_payment.value, item.external_payment.currency.code))+" ")])]):_vm._e(),(item.document !== null)?_c('tr',[_c('td',{staticClass:"border-right"},[_vm._v(" "+_vm._s(_vm.$t('document'))+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.document.number)+" "),_c('v-icon',[_vm._v(" "+_vm._s(_vm.getDocumentTypeIcon(item.document.type))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getDocumentTypeName(item.document.type))+" ")])])],1)]):_vm._e(),(item.carwash !== null)?_c('tr',[_c('td',{staticClass:"border-right"},[_vm._v(" "+_vm._s(_vm.$t('form.carwash'))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.carwash.long_name)+" ")])]):_vm._e(),(item.stand_code !== null)?_c('tr',[_c('td',{staticClass:"border-right"},[_vm._v(" "+_vm._s(_vm.$t('stand'))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.stand_code)+" ")])]):_vm._e()])])],1)],1):_vm._e()]}}])}),_c('add-top-up-modal',{ref:"AddTopUpModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }