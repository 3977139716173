function customCurrency(value, currency) {
  if (currency === 'credit' || currency === undefined) {
    return `${value} C`;
  }

  if (value === null) {
    return '-';
  }

  const formatter = new Intl.NumberFormat('pl', {
    style: 'currency',
    currency,
  });

  return formatter.format(value);
}

export default (Vue) => [
  Vue.filter('customCurrency', customCurrency),
];
