<script>
export default {
  name: 'SalesDocumentMixin',
  methods: {
    getDocumentTypeIcon(type) {
      if (type === 'invoice') {
        return 'mdi-file-document-outline';
      }
      if (type === 'receipt') {
        return 'mdi-receipt';
      }
      if (type === 'code') {
        return 'mdi-sale';
      }

      return 'unknown';
    },
    getDocumentTypeName(type) {
      if (type === 'invoice') {
        return this.$t('invoice');
      }
      if (type === 'receipt') {
        return this.$t('receipt');
      }
      if (type === 'code') {
        return this.$t('table.topup_code');
      }

      return this.$t('transactions.unknown');
    },
  },
};
</script>
