/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import Vue from 'vue';

const state = () => ({
  status: '',
  access_token: localStorage.getItem('access_token') || null,
  user: {},
});

const getters = {
  isLoggedIn: (state) => state.access_token !== null,
  getUser: (state) => state.user,
  getCurrency: (state) => state.user.currency.symbol,
  getCurrencyCode: (state) => state.user.currency.code,
  isFleetManager: (state) => state.user?.fleet_manager,
  isFleetMember: (state) => state.user?.fleet_member,
  isTermsAccepted: (state) => state.user.terms !== false,
  alerts: (state) => state.user?.alerts,
  userLocale: (state) => state.user?.language,
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, {
    accessToken,
  }) {
    state.status = 'success';
    state.access_token = accessToken;
  },
  user_success(state, {
    user,
  }) {
    state.user = user;
  },
  auth_error(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.user = {};
    state.access_token = null;
  },
};

const actions = {
  async initUser({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/api/users/info')
        .then((resp) => {
          commit('user_success', {
            user: resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('access_token');
          reject(err);
        });
    });
  },
  loginFromOauth({ commit }, accessToken) {
    localStorage.setItem('access_token', accessToken);
    Vue.axios.defaults.headers.common.Authorization = accessToken;
    commit('auth_success', {
      accessToken,
    });
  },
  async login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      Vue.axios.post('/api/v3/user/login', { email: user.username, plainPassword: user.password })
        .then((resp) => {
          const accessToken = resp.data.token;
          localStorage.setItem('access_token', accessToken);
          Vue.axios.defaults.headers.common.Authorization = accessToken;
          commit('auth_success', {
            accessToken,
          });
          resolve(resp);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('access_token');
          reject(err);
        });
    });
  },
  async logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('access_token');
      delete Vue.axios.defaults.headers.common.Authorization;
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
