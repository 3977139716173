<template>
  <v-app>
    <page-table />
  </v-app>
</template>

<script>
import PageTable from '@components/users/UsersTable.vue';

export default {
  components: {
    PageTable,
  },
};
</script>
