<template>
  <v-main
    class="fill-height bg-image"
  >
    <v-container
      fluid
      justify-center
      fill-height
    >
      <v-container>
        <v-row
          class="flex-column"
          align="center"
          justify="center"
        >
          <div class="bl-logo__wrapper">
            <v-img
              class="bl-logo"
              contain
            />
          </div>
          <v-alert
            transition="slide-y-transition"
            :value="!!message.text"
            :type="message.type"
          >
            {{ message.text }}
          </v-alert>
          <v-card
            class="d-block w-100 mb-7"
            width="650"
            elevation="6"
            raised
            outlined
          >
            <v-btn
              v-show="tab"
              class="mr-2 ml-n8"
              x-small
              color="primary"
              dark
              elevation="2"
              fab
              absolute
              top
              left
              @click="tab = 0"
            >
              <v-icon dark>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <v-tabs-items v-model="tab">
              <!--Login-->
              <v-tab-item
                key="loginTab"
              >
                <v-card-title>
                  <v-row>
                    <v-col>
                      {{ $t('login.login') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-form
                  ref="loginForm"
                  @submit.prevent="onLoginSubmit()"
                >
                  <v-card-text class="py-0">
                    <v-text-field
                      v-model="email"
                      prepend-icon="account_circle"
                      :rules="[rules.required, rules.email]"
                      :label="$t('login.email')"
                      required
                    />
                    <v-text-field
                      v-model="password"
                      prepend-icon="mdi-key"
                      :append-icon="loginPasswordInputAppendIcon"
                      :rules="[rules.required]"
                      :type="loginPasswordInputType"
                      :label="$t('password')"
                      @click:append="onLoginPasswordShowClick"
                    />
                  </v-card-text>
                  <v-card-text class="pt-0">
                    <v-checkbox
                      v-model="login.remember"
                      hide-details
                      :label="$t('login.remember')"
                    />
                  </v-card-text>
                  <v-card-text class="pt-0 pb-2 justify-center align-center text-center">
                    <v-btn
                      color="primary"
                      :loading="loading"
                      @click="onLoginSubmit()"
                    >
                      {{ $t('login.login') }}
                      <v-icon
                        right
                        dark
                      >
                        send
                      </v-icon>
                    </v-btn>
                  </v-card-text>
                </v-form>
                <v-card-text>
                  <v-row>
                    <v-col class="justify-center align-center text-center pt-0">
                      <v-btn
                        color="#4285F4"
                        end
                        bottom
                        dark
                        class="pl-1"
                        @click="googleLogin()"
                      >
                        <v-img
                          class="pt-0"
                          contain
                          style="height:35px"
                          src="@assetsCommon/images/log-in/google-icon.svg"
                        />
                        {{ $t('login.sign-in.google') }}
                      </v-btn>
                    </v-col>
                    <!--
                    <v-col class="justify-center align-center text-center pt-0">
                      <v-btn
                        color="#3b5998"
                        dark
                        bottom
                        @click="facebookLogin()"
                      >
                        <v-icon class="mr-2">
                          facebook
                        </v-icon>
                        {{ $t('login.sign-in.facebook') }}
                      </v-btn>
                    </v-col>
                    -->
                  </v-row>
                </v-card-text>
              </v-tab-item>

              <!--Register-->
              <v-tab-item
                key="registerTab"
              >
                <v-card-title>
                  <v-row>
                    <v-col class="align-center">
                      <span class="d-inline-block">{{ $t('login.register') }}</span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <template
                  v-if="!registerLock"
                >
                  <v-form
                    ref="registerForm"
                    @submit.prevent="onRegisterSubmit()"
                  >
                    <v-card-text class="py-0">
                      <v-text-field
                        v-model="email"
                        autofocus
                        prepend-icon="account_circle"
                        :rules="[rules.required, rules.email]"
                        :label="$t('login.email')"
                        required
                      />
                      <v-text-field
                        v-model="register.password"
                        prepend-icon="mdi-key"
                        :rules="[rules.required, rules.min]"
                        type="password"
                        :label="$t('password')"
                        required
                      />
                      <v-text-field
                        v-model="register.passwordRepeat"
                        prepend-icon="mdi-key-change"
                        :rules="[rules.required, rules.min, rules.passwordSame]"
                        type="password"
                        :label="$t('repeat_password')"
                        required
                      />
                      <v-text-field
                        v-model="register.nip"
                        prepend-icon="mdi-id-card"
                        :rules="[rules.nipOrNull]"
                        :label="$t('tax_id')"
                      />
                      <v-checkbox
                        v-model="register.termsOfUse"
                        :rules="[rules.required]"
                        required
                      >
                        <div slot="label">
                          {{ $t('i_accept') }}
                          <v-btn
                            text
                            elevation="0"
                            class="pl-1 pb-1"
                            @click="showTermsOfUse()"
                          >
                            {{ $t('terms_of_use') }}
                          </v-btn>
                        </div>
                      </v-checkbox>
                      <v-checkbox
                        v-model="register.rodo"
                        :rules="[rules.required]"
                        required
                      >
                        <div slot="label">
                          {{ $t('login.i-accept') }}
                          <v-btn
                            text
                            elevation="0"
                            class="pl-1 pb-1"
                            @click="showRodo()"
                          >
                            {{ $t('login.rodo') }}
                          </v-btn>
                        </div>
                      </v-checkbox>
                      <v-checkbox
                        v-model="register.newsletter"
                        :label="$t('login.newsletter')"
                      />
                    </v-card-text>
                    <v-card-text class="pt-0 justify-center align-center text-center">
                      <v-btn
                        color="primary"
                        :loading="loading"
                        @click="onRegisterSubmit()"
                      >
                        {{ $t('login.register') }}
                        <v-icon
                          right
                          dark
                        >
                          send
                        </v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-form>
                </template>
                <template v-else>
                  <v-card-text>
                    <div>
                      <h3
                        class="mb-5
                        text-center"
                      >
                        {{ $t('login.register_via_app') }}
                      </h3>
                    </div>
                    <v-row justify-center>
                      <v-col
                        md="6"
                        sm="6"
                        xs="6"
                        class="text-center"
                      >
                        <a :href="androidAppUrl">
                          <img
                            justify-center
                            style="height: 85px"
                            src="~@assetsCommon/images/stores/google-play-badge.png"
                          >
                        </a>
                      </v-col>
                      <v-col
                        md="6"
                        sm="6"
                        xs="6"
                        class="text-center"
                      >
                        <a :href="appleAppUrl">
                          <img
                            justify-center
                            class="mt-3"
                            style="height: 58px"
                            src="~@assetsCommon/images/stores/app-store-badge.png"
                          >
                        </a>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </template>
              </v-tab-item>

              <!--Forgot password-->
              <v-tab-item
                key="forgotTab"
              >
                <v-card-title>
                  <v-row>
                    <v-col>
                      {{ $t('login.password_reset') }}
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-form
                  ref="forgotForm"
                  @submit.prevent="onForgotSubmit()"
                >
                  <v-card-text class="py-0">
                    <v-text-field
                      v-model="email"
                      autofocus
                      prepend-icon="account_circle"
                      :rules="[rules.required, rules.email]"
                      :label="$t('login.email')"
                      required
                    />
                  </v-card-text>
                  <v-card-text class="pt-0 justify-center align-center text-center">
                    <v-btn
                      color="primary"
                      :loading="loading"
                      @click="onForgotSubmit()"
                    >
                      {{ $t('login.password_reset') }}
                      <v-icon
                        right
                        dark
                      >
                        send
                      </v-icon>
                    </v-btn>
                  </v-card-text>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
            <v-speed-dial
              v-if="false"
              v-model="mobileStoreSpeedDialOpen"
              absolute
              bottom
              right
              :direction="mobileStoreSpeedDialDirection"
              open-on-hover
              transition="slide-x-transition"
            >
              <template #activator>
                <v-btn
                  v-model="mobileStoreSpeedDialOpen"
                  small
                  elevation="2"
                  color="primary"
                  fab
                >
                  <v-icon dark>
                    phone_android
                  </v-icon>
                </v-btn>
              </template>
              <v-btn
                small
                dark
                color="green"
                fab
                href="#"
              >
                <v-icon dark>
                  android
                </v-icon>
              </v-btn>
              <v-btn
                small
                dark
                color="black"
                fab
                href="#"
              >
                <v-icon dark>
                  apple
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </v-card>
          <v-row
            class="d-flex"
            align="center"
            justify="space-between"
          >
            <v-col
              cols="12"
              md="6"
              class="text-center"
            >
              <v-btn
                text
                dark
                color="primary"
                :disabled="tab === 2"
                @click="tab = 2"
              >
                {{ $t('login.password_forgot') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
            >
              <v-btn
                text
                dark
                color="primary"
                :disabled="tab === 1"
                @click="tab = 1"
              >
                {{ $t('login.register') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            class="d-flex"
            align="center"
            justify="space-between"
          >
            <v-col
              md="6"
              sm="6"
              xs="6"
              class="mt-3 text-center py-0"
            >
              <a
                :href="androidAppUrl"
              >
                <img
                  justify-center
                  style="height: 85px"
                  src="~@assetsCommon/images/stores/google-play-badge.png"
                >
              </a>
            </v-col>
            <v-col
              md="6"
              sm="6"
              xs="6"
              class="text-center mt-0 py-0"
            >
              <a
                :href="appleAppUrl"
              >
                <img
                  justify-center
                  class="mt-3"
                  style="height: 58px"
                  src="~@assetsCommon/images/stores/app-store-badge.png"
                >
              </a>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-container>
  </v-main>
</template>

<script>

import axios from '@plugins/axios';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginPage',
  data() {
    return {
      loading: false,
      tab: 0,
      email: '',
      password: '',
      message: {
        type: 'error',
        text: null,
      },
      messages: {
        loginFailure: {
          type: 'error',
          text: this.$t('login.messages.login_failure'),
        },
        registerSuccess: {
          type: 'success',
          text: this.$t('login.messages.register_success'),
        },
        registerFailure: {
          type: 'error',
          text: this.$t('login.messages.register_failure'),
        },
        forgotSuccess: {
          type: 'success',
          text: this.$t('reset_password_info'),
        },
        forgotFailure: {
          type: 'error',
          text: this.$t('login.messages.forgot_failure'),
        },
      },
      login: {
        password: '',
        showPassword: false,
        remember: false,
      },
      register: {
        password: '',
        passwordRepeat: '',
        nip: '',
        newsletter: false,
        rodo: false,
        termsOfUse: false,
      },
      mobileStoreSpeedDialOpen: false,
      rules: {
        required: (v) => !!v || this.$t('field_is_required'),
        passwordSame: (v) => v === this.register.password || this.$t('form.validation.password_same'),
        min: (v) => v.length >= 6 || this.$t('form.validation.min'),
        email: (v) => !!v.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) || this.$t('form.validation.email'),
        nipOrNull: (v) => {
          if (v.length === 0) {
            return true;
          }
          // eslint-disable-next-line no-param-reassign
          v = v.replace(/[ -]/gi, '');
          const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
          let sum = 0;
          const controlNumber = parseInt(v.substring(9, 10), 10);
          const weightCount = weight.length;
          for (let i = 0; i < weightCount; i += 1) {
            sum += (parseInt(v.substr(i, 1), 10) * weight[i]);
          }

          return sum % 11 === controlNumber || this.$t('form.validation.nip');
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      rodoHref: 'branding/gdpr_link',
      termsOfUseHref: 'branding/terms_link',
      apiUrl: 'branding/apiUrl',
      registerLock: 'branding/register_www_lock',
      appleAppUrl: 'branding/apple_app_url',
      androidAppUrl: 'branding/android_app_url',
    }),
    loginPasswordInputAppendIcon() {
      if (this.login.showPassword) {
        return 'mdi-eye-off';
      }
      return 'mdi-eye';
    },
    loginPasswordInputType() {
      if (this.login.showPassword) {
        return 'text';
      }
      return 'password';
    },
    mobileStoreSpeedDialDirection() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'right';
      }
      return 'top';
    },
  },
  methods: {
    showRodo() {
      window.open(this.rodoHref, '_blank');
    },
    showTermsOfUse() {
      window.open(this.termsOfUseHref, '_blank');
    },
    facebookLogin() {
      window.location.href = `${this.apiUrl}/connect/facebook`;
    },
    googleLogin() {
      window.location.href = `${this.apiUrl}/connect/google`;
    },
    onLoginPasswordShowClick() {
      this.login.showPassword = !this.login.showPassword;
    },
    async onLoginSubmit() {
      this.loading = true;
      this.$store.dispatch(
        'auth/login',
        { username: this.email, password: this.password },
      ).then(
        () => {
          this.$router.push('/transactions');
        },
      ).catch(
        () => {
          this.error = true;
          this.message = { ...this.messages.loginFailure };
        },
      ).finally(() => {
        this.loading = false;
      });
    },
    onRegisterSubmit() {
      const { email } = this;
      const { password, newsletter, nip } = this.register;
      const isFormValid = this.$refs.registerForm.validate();
      if (isFormValid) {
        this.loading = true;
        axios.post('api/users/registers', {
          email,
          plainPassword: password,
          newsletter,
          lang: process.env.VUE_APP_I18N_LOCALE,
          nip,
        }).then((response) => {
          if (response.data.status === false) {
            return Promise.reject(response);
          }
          this.loading = false;
          this.tab = 0;
          this.message = { ...this.messages.registerSuccess };
          return true;
        }).catch((err) => {
          this.loading = false;
          this.message = { ...this.messages.registerFailure };
          this.message.text += this.$t(err.data.message);
        });
      }
    },
    onForgotSubmit() {
      const { email } = this;
      const isFormValid = this.$refs.forgotForm.validate();
      if (isFormValid) {
        this.loading = true;
        axios.post('api/users/resets', {
          email,
        }).then((response) => {
          if (response.data.status === false) {
            return Promise.reject(response);
          }
          this.loading = false;
          this.tab = 0;
          this.message = { ...this.messages.forgotSuccess };

          return true;
        }).catch((err) => {
          this.loading = false;
          this.message = { ...this.messages.forgotFailure };
          this.message.text += this.$t(err.message);
        });
      }
      return false;
    },
  },
};
</script>
