export default {
  icons: {
    BKFPAY: 'phonelink_ring', //  'account_balance_wallet'
    BE_LOYAL: 'card_membership',
    CREDIT_CARD: 'credit_card',
    P24: 'account_balance',
  },
  toastTimeout: 3000,
  payment_api_methods_id: {
    prepare: 'preparePayment',
    init: 'initPayment',
  },
};
