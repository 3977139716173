<template>
  <v-select
    :multiple="!single"
    :items="inputItems"
    :disabled="disabled"
    :prepend-icon="prependIcon"
    :label="label"
    @input="emitValue"
  >
    <template
      #item="{item, attrs, on}"
    >
      <v-list-item
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-action v-show="!single">
          <v-checkbox
            :input-value="attrs.inputValue"
            :disabled="item.disabled"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-row
              no-gutters
              align="center"
            >
              <span :class="{'text--disabled': item.disabled}">{{ item.text }}</span>
              <v-spacer />
              <v-icon :disabled="item.disabled">
                {{ item.icon }}
              </v-icon>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    single: {
      type: Boolean,
      default: false,
    },
    // Will only work when you have option A, B and both (A+B)
    unified: {
      type: Boolean,
      default: false,
    },
    allowNull: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, Object, String, Number, Boolean],
      default() {
        return [];
      },
    },
    returnArray: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: [String, Object],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputValue() {
      if (this.single || this.allowNull) {
        return this.value;
      }
      if (this.value === null) {
        return this.items.map((item) => item.value);
      }
      if (!Array.isArray(this.value)) {
        return [this.value];
      }
      return this.value;
    },
    inputItems() {
      if (!this.allowNull && !this.single && this.inputValue.length === 1) {
        return this.items.map((item) => ({
          ...item,
        }));
      }
      return this.items;
    },
  },
  methods: {
    emitValue(value) {
      this.$emit('input', this.calculateOutputValue(value));
    },
    calculateOutputValue(value) {
      if (!this.returnArray && (!this.unified || this.items.length > 2)) {
        return value;
      }
      if (this.returnArray && (!this.unified || this.items.length > 1)) {
        return value;
      }
      if (Array.isArray(value) && !value.length) {
        return null;
      }
      if (!Array.isArray(value)) {
        // eslint-disable-next-line no-param-reassign
        value = [value];
      }
      if (this.items.length === value.length) {
        return null;
      }
      return value[0];
    },
  },
};
</script>
