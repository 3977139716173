<template>
  <v-text-field
    :label="$t('table.search')"
    prepend-icon="mdi-magnify"
    :disabled="disabled"
    clearable
    :value="value"
    :rules="rules"
    :hide-details="!rules.length"
    @input="onChange"
    @click:clear="onChange"
  />
</template>

<script>

export default {
  name: 'TextSearch',
  props: {
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debounceTime: 1500,
      filtering: {
        search: null,
      },
    };
  },
  methods: {
    onChange(searchText) {
      this.$set(this.filtering, 'search', searchText === null ? searchText : searchText.trim());
      this.$emit('input', searchText);
    },
  },
};
</script>
