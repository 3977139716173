<template>
  <v-stepper
    v-model="step"
    class="elevation-0"
  >
    <v-stepper-header class="hidden-sm-and-down">
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        {{ stepTitles[1] }}
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        :complete="step > 2"
        step="2"
      >
        {{ stepTitles[2] }}
      </v-stepper-step>
      <v-divider />
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <div
          class="text-center mb-12"
          color="grey lighten-1"
        >
          <template v-if="loading">
            <v-progress-circular
              :width="5"
              :size="70"
              indeterminate
              color="primary"
            />
          </template>
          <template v-else>
            <div v-if="isFeatureAvailable">
              <post-paid-invoices-list
                v-if="invoices.length"
                :items="invoices"
              />
              <div v-if="!isPaymentDenied">
                <h2 class="text-center">
                  {{ $t('top_up.chose_payment_amount') }}
                </h2>
                <h1 class="text-center">
                  {{ $n(topUpValue, currencyCode) }}
                </h1>
                <v-card-text class="pt-0 pb-0">
                  <v-slider
                    v-model="topUpValue"
                    :max="topUpMax"
                    :min="topUpMin"
                    thumb-label
                    :step="topUpValueStepSize"
                    ticks
                  />
                </v-card-text>
              </div>
              <div v-else>
                <h2 class="text-center">
                  {{ paymentDeniedHeader }}
                </h2>
                <p class="text-center">
                  {{ paymentDeniedMessage }}
                </p>
              </div>
              <div>
                <v-btn
                  large
                  color="primary"
                  :disabled="isPaymentDenied"
                  @click="makePayment()"
                >
                  {{ $t('top_up.top_up') }}
                </v-btn>
              </div>
            </div>
            <h1 v-else>
              {{ $t('top_up.feature_not_available') }}
            </h1>
          </template>
        </div>
      </v-stepper-content>
      <v-stepper-content step="2">
        <div
          class="text-center mb-12"
          color="grey lighten-1"
          height="200px"
        >
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          />
          <h1>{{ $t('top_up.procesing') }}</h1>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import common from '@components/financial/common.js';
import { mapGetters } from 'vuex';
import axios from '@plugins/axios';
import PostPaidInvoicesList from './PostPaidInvoicesList.vue';

export default {
  components: {
    PostPaidInvoicesList,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    paySystem: {
      type: String,
      required: true,
    },
    startStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      paymentPaySystem: this.paySystem,
      paymentToken: this.token,
      loading: false,
      stepTitles: {
        1: this.$t('top_up.step.chose_value'),
        2: this.$t('top_up.step.processing'),
      },
      topUpValue: 50,
      topUpValueStepSize: 10,
      topUpMin: 50,
      topUpMax: 5000,
      invoices: [],
      isPaymentDenied: false,
      paymentDenyReason: '',
      isFeatureAvailable: false,
    };
  },
  watch: {
    startStep(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.step = newVal;
      }
    },
  },
  computed: {
    ...mapGetters({
      appUrl: 'branding/url',
      currency: 'auth/getCurrency',
      getUser: 'auth/getUser',
      currencyCode: 'auth/getCurrencyCode',
    }),
    paymentDeniedHeader() {
      switch (this.paymentDenyReason) {
        case 'limit_exceeded': return this.$t('top_up.limit_exceeded_header');
        case 'expired_invoice_found': return this.$t('top_up.expired_invoice_found_header');
        default: return this.$t('top_up.general_error_header');
      }
    },
    paymentDeniedMessage() {
      switch (this.paymentDenyReason) {
        case 'limit_exceeded': return this.$t('top_up.limit_exceeded_message');
        case 'expired_invoice_found': return this.$t('top_up.expired_invoice_found_message');
        default: return this.$t('top_up.general_error_message');
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.preparePayment();
    this.loading = false;
  },
  methods: {
    redirectToUrl(url) {
      window.location = url;
    },
    async preparePayment() {
      try {
        const response = await axios.post(
          '/api/users/mobilepayment',
          {
            methodId: common.payment_api_methods_id.prepare,
            pay_system: this.paymentPaySystem,
          },
        );

        const { data } = response;

        if (data && (data.length === 0 || data.status === 'success')) {
          this.topUpMin = data.min ?? 1;
          this.topUpValue = data.min ?? 10;
          this.topUpMax = data.max ?? 200;
          this.invoices = data.invoices ?? [];
          this.isPaymentDenied = data.isPaymentDenied ?? false;
          this.paymentDenyReason = data.paymentDenyReason ?? '';

          this.isFeatureAvailable = true;
        }
      } catch {
        this.loading = false;
      }
    },
    makePayment() {
      this.step = 2;

      const date = new Date();
      const timestamp = `${date.getUTCFullYear()}-${date.getUTCMonth()
      + 1}-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}`;
      const data = {
        methodId: common.payment_api_methods_id.init,
        appName: `www-${process.env.VUE_APP_NAME}`,
        pay_system: this.paymentPaySystem,
        token: this.paymentToken,
        timestamp: timestamp.toString(),
        value: this.topUpValue * 100,
        onSuccess: `${this.appUrl}/#/top-up/0`,
      };

      axios.post(
        '/api/users/mobilepayment',
        data,
      )
        .then((response) => {
          if (response.data && response.data.status === 'success') {
            localStorage.setItem('last_payment_id', response.data.paymentId);
            if (response.data && response.data.paymentUrl) {
              this.redirectToUrl(response.data.paymentUrl);
            }
            this.successfullyAdded();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    successfullyAdded() {
      this.$eventHub.$emit('application-refresh-balance');
      this.$eventHub.$emit('application-refresh-top-up-list');
      this.getLastTopupData();
    },
    getLastTopupData() {
      this.$emit(
        'showPaymentSteps',
        {
          token: '',
          paySystem: '',
          paymentTypeKey: 'summary',
          step: 1,
        },
      );
    },
  },
};
</script>

<style scoped>
h2 {
  padding-top: 30px;
  padding-bottom: 10px;
}
</style>
