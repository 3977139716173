<template>
  <v-app>
    <div
      class="text-center mb-12 pt-12"
      color="grey lighten-1"
      height="200px"
    >
      <v-icon
        style="font-size: 76px"
        color="green darken-2"
      >
        check_circle_outline
      </v-icon>
      <h1>{{ $t('payment_success') }}</h1>
    </div>
  </v-app>
</template>

<script>

export default {
};
</script>
