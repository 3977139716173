<template>
  <v-container>
    <v-row>
      <v-dialog
        v-model="dialog"
        max-width="700"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
            flat
          >
            <v-btn
              icon
              dark
              @click.native="dialog = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <v-icon>account_balance_wallet</v-icon>
              {{ $t('top_up.top_up_account') }}
            </v-toolbar-title>
          </v-toolbar>
          <v-progress-linear
            class="no-margin"
            :indeterminate="loading"
          />
          <add-top-up-method-tab
            v-if="methodChoseShow"
            @showPaymentSteps="showPaymentMethodSteps"
          />
          <add-top-up-payment-steps
            v-if="paymentShow"
            :start-step="step"
            :pay-system="paySystem"
            :token="token"
            @showPaymentSteps="showPaymentMethodSteps"
            @close="onClose"
          />
          <add-top-up-summary
            v-if="summaryShow"
            @close="onClose"
          />
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import addTopUpMethodTab from '@components/topupsValue/AddTopUpMethodTab.vue';
import AddTopUpPaymentSteps from '@components/topupsValue/AddTopUpPaymentSteps.vue';
import AddTopUpSummary from '@components/topupsValue/AddTopUpSummary.vue';

export default {
  components: {
    addTopUpMethodTab,
    AddTopUpPaymentSteps,
    AddTopUpSummary,
  },
  data() {
    return {
      method: 'chose-method',
      paySystem: null,
      token: null,
      topUpId: this.$route.params.top_up_id,
      step: 1,
      dialog: false,
      loading: false,
      dataExists: false,
      topUpsPackagesData: {},
      methodChoseShow: true,
      paymentShow: false,
      summaryShow: false,
    };
  },
  watch: {
    dialog(val) {
      if (!val) {
        // custom method on close dialog by clicking outside
        this.onClose();
      }
    },
    method(val) {
      this.showMethod(val);
    },
  },
  created() {
    if (Number(this.topUpId) === 0) {
      this.method = 'summary';
      this.showMethod(this.method);
    }
  },
  mounted() {
    if (Number(this.topUpId) === 0) {
      this.method = 'summary';
      this.showMethod(this.method);
    }
  },
  methods: {
    showPaymentMethodSteps(params) {
      const {
        token,
        paySystem,
        paymentTypeKey,
        step,
      } = params;

      this.step = step;
      this.method = paymentTypeKey;
      this.paySystem = paySystem;
      this.token = token;
      this.showMethod(paymentTypeKey);
    },
    showMethod(paymentTypeKey) {
      switch (paymentTypeKey) {
        case 'chose-method': {
          this.methodChoseShow = true;
          this.paymentShow = false;
          this.summaryShow = false;
          break;
        }
        case 'payment': {
          this.methodChoseShow = false;
          this.paymentShow = true;
          this.summaryShow = false;
          break;
        }
        case 'summary': {
          this.methodChoseShow = false;
          this.paymentShow = false;
          this.summaryShow = true;
          break;
        }
        default: {
          break;
        }
      }
    },
    onClose() {
      this.$validator.reset();
      localStorage.removeItem('last_payment_id');
      setTimeout(() => {
        // waits till modal hide
        this.step = 1;
        this.methodChoseShow = true;
        this.paymentShow = false;
        this.summaryShow = false;
      }, 300);
    },
  },
};
</script>
