<template>
  <div style="padding-left: 20px; padding-right: 20px;">
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :loading="loading"
      :no-data-text="noDataTranslation"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      class="transparent"
    >
      <template #progress>
        <div class="text-center">
          <v-progress-linear
            class="loader"
            indeterminate
            color="primary"
          />
        </div>
      </template>

      <template #item="{ item }">
        <tr
          v-if="!loading"
        >
          <td class="border-right">
            {{ item.number }}
          </td>
          <td class="hidden-xs-only text-sm-end">
            {{ $d(new Date(item.issuance_date.replace(' ', 'T')), 'long') }}
          </td>
          <td class="hidden-xs-only text-sm-end border-right">
            {{ $d(new Date(item.payment_date.replace(' ', 'T')), 'long') }}
          </td>
          <td
            class="text-sm-end"
            nowrap
          >
            {{ $n(item.price_excluded_tax, item.currency.code) }}
          </td>
          <td
            class="text-sm-end"
            nowrap
          >
            {{ $n(item.tax_amount, item.currency.code) }}
          </td>
          <td
            class="text-sm-end border-right"
            nowrap
          >
            {{ $n(item.price, item.currency.code) }}
          </td>
          <td class="text-sm-end">
            <btn-download
              small
              @click="invoiceDownload(item)"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import common from '@components/financial/common.js';
import axios from '@plugins/axios';
import BtnDownload from '@/common/BtnDownload.vue';
import ExportMixin from '@components/mixins/ExportMixin.vue';

export default {
  components: {
    BtnDownload,
  },
  mixins: [
    ExportMixin,
  ],
  data() {
    return {
      loading: true,
      search: '',
      selected: [],
      footerProps: {
        'items-per-page-options': [10, 15, 25, 50],
        'items-per-page-text': this.$t('table.rows_per_page'),
      },
      pagination: {
        sortBy: ['issuance_date'],
        sortDesc: [true],
        itemsPerPage: 10,
        page: 1,
      },
      icons: common.icons,
      headers: [
        {
          text: this.$i18n.t('invoice.number'),
          value: 'number',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$i18n.t('invoices.issuance-date'),
          align: 'right',
          value: 'issuance_date',
          class: 'hidden-xs-only',
          sortable: false,
        },
        {
          text: this.$i18n.t('invoices.payment-date'),
          align: 'right',
          value: 'payment_date',
          class: 'hidden-xs-only',
          sortable: false,
        },
        {
          text: this.$i18n.t('invoice.nett'),
          value: 'price_excluded_tax',
          align: 'right',
          sortable: false,
        },
        {
          text: this.$i18n.t('invoices.vat'),
          value: 'tax_amount',
          align: 'right',
          sortable: false,
        },
        {
          text: this.$i18n.t('payment_value'),
          value: 'price',
          align: 'right',
          sortable: false,
        },
        {
          text: '',
          sortable: false,
        },
      ],
      noDataTranslation: this.$i18n.t('no_data'),
      items: [],
      totalItems: 0,
    };
  },
  watch: {
    pagination: {
      handler(newValue, oldValue) {
        if (oldValue.sortDesc !== newValue.sortDesc
            || oldValue.page !== newValue.page
            || oldValue.itemsPerPage !== newValue.itemsPerPage
            || oldValue.sortBy !== newValue.sortBy) {
          // return to first page when sorting has change
          if (oldValue.sortDesc !== newValue.sortDesc
              || oldValue.sortBy !== newValue.sortBy
              || oldValue.itemsPerPage !== newValue.itemsPerPage
          ) {
            // eslint-disable-next-line no-param-reassign
            newValue.page = 1;
            this.getData(true);
            return;
          }
          this.getData();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    invoiceDownload(item) {
      const url = `/api/users/invoice/download/${item.id}`;
      const filename = `${item.number}.pdf`;
      this.onExport({
        url,
        filetype: 'application/pdf',
        filename,
      });
    },
    getData() {
      this.loading = true;
      axios.get(
        '/api/users/invoice',
        {
          params: {
            pageNumber: this.pagination.page,
            // order: this.pagination.sortBy[0],
            // desc: this.pagination.sortDesc[0],
            itemsPerPage: this.pagination.itemsPerPage,
          },
        },
      )
        .then((response) => {
          if (response.data) {
            this.items = response.data.items;
            this.totalItems = response.data.totalItems;
            this.loading = false;
          }
        });
    },
  },
};
</script>
